import { Typography } from "@material-tailwind/react";
import React from "react";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  SecondaryButtonWithIconOnTop,
  PrimaryButtonWithIconOnTop,
  GhostButton,
} from "../components/Buttons";
import ModalBlock from "../components/ModalBlock";
import { MoonIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Input, TextArea } from "../components/FormElements";
import { Table } from "../components/Table";

const DesignPage = () => {
  const [formTextValue, setFormTextValue] = useState("");

  const [open, setOpen] = useState(false);
  function toggleModal() {
    setOpen(!open);
  }
  return (
    <div className="p-2">
      <section className="mb-8 flex flex-col gap-4">
        <h2 className="text-2xl font-bold text-light-green-700">Typography</h2>
        <hr className="my-2"></hr>
        <Typography variant="h1" className="text-2xl">
          Heading 1
        </Typography>
        <Typography variant="h2" className="text-xl">
          Heading 2
        </Typography>
        <Typography variant="h3" className="text-xl">
          Heading 3
        </Typography>

        <Typography
          variant="paragraph"
          className="text-sm font-normal font-outfit"
        >
          From footballers to singers, this month’s AI or real quiz is sure to
          leaving you scratching your head!Challenge yourself to get full marks
          in our latest AI or real quiz and see how you do at spotting pictures
          that have been created using artificial intelligence.And don’t forget,
          do your own research to find out where the original images and videos
          came from, verify sources, and check to see if credible news sites
          have covered the story.
        </Typography>
      </section>

      <section className="mb-8 flex flex-col gap-4">
        <h2 className="text-2xl font-bold text-light-green-700">Button</h2>
        <hr className="my-2"></hr>
        <div className="flex flex-row gap-4">
          <PrimaryButton>Primary Button</PrimaryButton>
          <PrimaryButton lefticon={<MoonIcon className="h-4 w-4" />}>
            Primary Button
          </PrimaryButton>
          <PrimaryButton righticon={<MoonIcon className="h-4 w-4" />}>
            Primary Button
          </PrimaryButton>
        </div>
        <div className="flex flex-row gap-4">
          <SecondaryButton>Secondary Button</SecondaryButton>
          <SecondaryButton lefticon={<MoonIcon className="h-4 w-4" />}>
            Secondary Button
          </SecondaryButton>
          <SecondaryButton righticon={<MoonIcon className="h-4 w-4" />}>
            Secondary Button
          </SecondaryButton>
        </div>
        <div className="flex flex-row gap-4">
          {" "}
          <TertiaryButton>Tertiary Button</TertiaryButton>
          <TertiaryButton lefticon={<MoonIcon className="h-4 w-4" />}>
            Secondary Button
          </TertiaryButton>
          <TertiaryButton righticon={<MoonIcon className="h-4 w-4" />}>
            Secondary Button
          </TertiaryButton>
        </div>

        <div className="flex flex-row gap-4">
          <PrimaryButtonWithIconOnTop icon={<MoonIcon className="h-5 w-5" />}>
            Testing
          </PrimaryButtonWithIconOnTop>
          <SecondaryButtonWithIconOnTop icon={<MoonIcon className="h-5 w-5" />}>
            Testing
          </SecondaryButtonWithIconOnTop>
        </div>
      </section>

      <section className="mb-8 flex flex-col gap-4">
        <h2 className="text-2xl font-bold text-light-green-700">Modal Block</h2>
        <hr className="my-2"></hr>
        <PrimaryButton onClick={toggleModal}>Open Modal Block</PrimaryButton>
        <ModalBlock
          title="Modal Block Testing"
          openStatus={open}
          secondaryButton={
            <SecondaryButton
              variant="text"
              color="text-red-500"
              className="mr-1"
              onClick={toggleModal}
            >
              <span>Cancel</span>
            </SecondaryButton>
          }
          primaryButton={
            <PrimaryButton variant="gradient" color="green">
              <span>Confirm</span>
            </PrimaryButton>
          }
        >
          <Input required={true}>Testing</Input>
          <TextArea required={false}>Testing</TextArea>
        </ModalBlock>
        {/* Add your dialog design elements here */}
      </section>

      <section className="mb-8 flex flex-col gap-4">
        <h2 className="text-2xl font-bold text-light-green-700">
          Form Elements
        </h2>
        <hr className="my-2"></hr>
        <Input
          required={true}
          onChange={(e) => setFormTextValue(e.target.value)}
          placeholder="Post Name"
          value={formTextValue}
        >
          Post Name
        </Input>
        <TextArea
          required={false}
          onChange={(e) => setFormTextValue(e.target.value)}
          value={formTextValue}
          fieldHeight="h-40"
          placeholder="Post Description"
        >
          Post Description
        </TextArea>
        <PrimaryButton
          onClick={() => {
            console.log(formTextValue);
          }}
        >
          Console log Form Value
        </PrimaryButton>
        <span className="text-sm font-medium font-outfit">
          {formTextValue}{" "}
        </span>
      </section>

      <section className="mb-8 flex flex-col gap-4">
        <h2 className="text-2xl font-bold text-light-green-700">Table Test</h2>
        <hr className="my-2"></hr>
        <Table
          actionItem={
            <GhostButton
              righticon={<MoonIcon className="w-4 h-4" />}
            ></GhostButton>
          }
        />
      </section>

      {/* Add more sections for other design elements as needed */}
    </div>
  );
};

export default DesignPage;
