import React, { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Typography, Card, CardBody } from "@material-tailwind/react";
import {
  TrashIcon,
  PencilSquareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import service from "../service";
import Loading from "../components/Loading";
import Message from "../components/Message";
import { PlusIcon } from "@heroicons/react/24/outline";
import { BasicHeader } from "../components/PageHeaderTypes";
import { GhostButton, PrimaryButton } from "../components/Buttons";
import CreateProfile from "./CreateProfile";
import EditProfile from "./EditProfile";

function Profiles() {
  const auth = useAuthHeader();

  const [profiles, setProfiles] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [profileIdEditing, setProfileIdEditing] = useState(false);

  // Fetch all profiles
  useEffect(() => {
    setLoading(true);
    service
      .getProfiles(auth())
      .then((data) => {
        setProfiles(data.profiles);
        if (data.profiles.length === 0) {
          setMessage("No profiles found.");
        }
        setLoading(false);
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  }, []);

  // Delete profile handler
  const deleteProfileHandler = async (profileId) => {
    setLoading(true);
    service
      .deleteProfile(auth(), profileId)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  return (
    <>
      {/* Header with create button */}
      <BasicHeader
        title="🤩 Profiles"
        actionItem={
          <PrimaryButton
            righticon={<PlusIcon className="w-4 h-4" />}
            onClick={() => setIsCreateModalOpen(true)}
          >
            Create Profiles
          </PrimaryButton>
        }
      />

      {/* Create profile modal */}
      <CreateProfile
        isCreateModalOpen={isCreateModalOpen}
        setIsCreateModalOpen={setIsCreateModalOpen}
        deleteProfileHandler={deleteProfileHandler}
      />

      {/* Edit profile modal */}
      <EditProfile
        profileId={profileIdEditing}
        setProfileId={setProfileIdEditing}
        deleteProfileHandler={deleteProfileHandler}
      />

      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      {profiles.length === 0 && (
        <Typography className="font-normal" color="blue-gray">
          Create a profile to get started
        </Typography>
      )}

      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 mt-6">
        {profiles &&
          profiles.map((profile) => (
            <Card className="mt-0 w-full shadow-[0px_0px_5px_0px_rgba(0,0,0,0.05)] hover:shadow-[0px_0px_5px_0px_rgba(0,0,0,0.1)]">
              <CardBody className="py-4 flex flex-row gap-4 items-start justify-start">
                <div className="w-14">
                  <UserIcon
                    src="avatar.png"
                    className={`h-14 w-14 p-2 object-cover border-2 bg-gray-100 text-gray-400 rounded-md  `}
                    alt="avator"
                  />
                </div>
                <div className="flex flex-col gap-1 flex-wrap w-full">
                  <Typography variant="h6" color="blue-gray">
                    {profile.name}
                  </Typography>
                  <Typography
                    variant="p"
                    className="text-xs font-normal text-gray- font-inter h-8 overflow-hidden"
                  >
                    {profile?.biography}
                  </Typography>
                  <div className="flex flex-row gap-3 pt-3 w-full justify-end">
                    <GhostButton
                      className=" border items-center gap-2 lowercase font-light"
                      onClick={() => setProfileIdEditing(profile.id)}
                      lefticon={<PencilSquareIcon className="h-4 w-4" />}
                    >
                      Edit
                    </GhostButton>
                    <GhostButton
                      className="border text-red-200 flex items-center gap-2"
                      onClick={() => deleteProfileHandler(profile.id)}
                      lefticon={<TrashIcon className="h-4 w-4" />}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
      </div>
    </>
  );
}

export default Profiles;
