import { useState } from "react";
import { Typography } from "@material-tailwind/react";
import Loading from "../../components/Loading";
import Message from "../../components/Message";
import ModalBlock from "../../components/ModalBlock";
import {
  PrimaryButton,
  SecondaryButton,
  GhostButton,
  TertiaryButton,
} from "../../components/Buttons";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Input } from "../../components/FormElements";
import { Table } from "../../components/Table";
import { searchNews, rssFeeds } from "../ragUtil";

function FetchRSSModal({ postModalPage, setPostModalPage, post, setPost }) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [category, setCategory] = useState("");
  const [articles, setArticles] = useState([]);

  // Fetch RSS handler
  const fetchRssHandler = async () => {
    setArticles([]);
    setLoading(true);
    setMessage("");
    try {
      const entries = await searchNews(keywords);
      setArticles(entries);
    } catch {
      setMessage("Web search failed");
    }
    setLoading(false);
  };

  // Select article handler, go to fetch URL modal
  const selectArticleHandler = (url) => {
    setPost({ ...post, url });
    setKeywords("");
    setArticles([]);
    setPostModalPage(4);
  };

  // Fetch search form layout
  return (
    <ModalBlock
      openStatus={postModalPage === 2}
      title="News Article Search"
      width="lg"
      classNameBody="h-[500px] overflow-y-auto"
      primaryButton={
        <PrimaryButton
          onClick={() => {
            fetchRssHandler();
          }}
        >
          Search
        </PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton
          onClick={() => {
            setPost(null);
            setKeywords("");
            setCategory("");
            setArticles([]);
            setPostModalPage(0);
          }}
        >
          Close
        </SecondaryButton>
      }
    >
      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      <div className="flex flex-row gap-4 w-full">
        <div className="w-full">
          <Input
            maxLength={100}
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            placeholder="e.g. 'national election' or 'AI robots'"
            required={true}
          >
            Search with Keywords
          </Input>
        </div>
      </div>

      <Typography className="font-normal text-xs mt-1">
        News Sources: {Object.keys(rssFeeds).join(", ")}
      </Typography>

      {articles.length > 0 ? (
        <Table
          data={articles.map(
            ({ similarity, title, snippet, url, feed }, i) => ({
              relevance: `${Math.trunc(similarity * 100)}%`,
              titleAndFeed: `${title} - ${feed}`,
              snippet,
              url,
            })
          )}
          tableMetaData={[
            {
              column: 1,
              minWidth: "20px",
              textProperties: "text-gray-800 font-semibold",
              customName: "Rel",
            },
            {
              column: 2,
              minWidth: "200px",
              textProperties: "text-gray-800 font-medium",
              customName: "Title",
            },
            {
              column: 3,
              minWidth: "400px",
              width: "60%",
              textProperties: "text-gray-600 font-medium",
              customName: "Content Snippet",
            },
            {
              column: 4,
              minWidth: "150px",
              textProperties: "text-gray-400 font-normal",
              customName: "Actions",
              customRender: (rowData) => (
                <div className="flex flex-row gap-2">
                  <GhostButton
                    onClick={() => window.open(rowData.url, "_blank")}
                    className="text-blue-500 underline mr-2"
                    righticon={
                      <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    }
                  ></GhostButton>
                  <TertiaryButton
                    onClick={() => selectArticleHandler(rowData.url)}
                    className="text-blue-500 underline"
                  >
                    Select
                  </TertiaryButton>
                </div>
              ),
            },
          ]}
        />
      ) : null}
    </ModalBlock>
  );
}

export default FetchRSSModal;
