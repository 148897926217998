import React, { useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Typography } from "@material-tailwind/react";
import service from "../service";
import Message from "../components/Message";
import Loading from "../components/Loading";

function SubscriptionBox() {
  const auth = useAuthHeader();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const subscriptionStatusHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .subscriptionStatus(auth())
      .then((data) => {
        setStatus(data);
        setLoading(false);
      })
      .catch((e) => {
        setMessage("Failed to get subscription status");
        setLoading(false);
      });
  };

  useEffect(() => {
    subscriptionStatusHandler();
  }, []);

  return (
    <>
      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}
      {status && (
        <div className="flex flex-col gap-2 shadow-lg rounded-xl bg-white p-4 mt-4 mb-8 max-w-2xl">
          <Typography className="text-md font-semibold mb-2 text-gray-700">
            👑 Account Level: {status.level} (using {status.profiles} /{" "}
            {status.maxProfiles} profiles and {status.posts} / {status.maxPosts}{" "}
            posts)
          </Typography>
          {status.level === "trial" && (
            <Typography className="text-md font-normal mb-2 text-gray-700">
              Welcome to your Viralizard AI trial account. To upgrade to the
              Unlimited or Enterprise plans, please reach out to us at
              contact@viralizard.com
            </Typography>
          )}
        </div>
      )}
    </>
  );
}

export default SubscriptionBox;
