import React, { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import service from "../service";
import ProfileModal from "./components/ProfileModal";

export default function CreateProfile({
  isCreateModalOpen,
  setIsCreateModalOpen,
}) {
  const auth = useAuthHeader();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState({
    name: "",
    biography: "",
    style: "",
  });

  // Create profile handler
  const createProfileHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .createProfile(auth(), profile)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  return (
    <ProfileModal
      profile={profile}
      setProfile={setProfile}
      handler={createProfileHandler}
      message={message}
      loading={loading}
      openStatus={isCreateModalOpen}
      setOpenStatus={setIsCreateModalOpen}
    />
  );
}
