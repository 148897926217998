import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

/* AUTHENTICATION */

const login = async (identifier, password) => {
  const req = axios.post(`${API_BASE_URL}/api/auth/local`, {
    identifier,
    password,
  });
  return req.then((res) => res.data);
};

const register = async (username, email, password) => {
  const req = axios.post(`${API_BASE_URL}/api/auth/local/register`, {
    username,
    email,
    password,
  });
  return req.then((res) => res.data);
};

const changePassword = async (
  header,
  currentPassword,
  password,
  passwordConfirmation
) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/auth/change-password`,
    headers: {
      Authorization: header,
    },
    data: { currentPassword, password, passwordConfirmation },
  });
  return req.then((res) => res.data);
};

/* PROFILES */

const getProfiles = async (header) => {
  const req = axios({
    method: "GET",
    url: `${API_BASE_URL}/api/profiles`,
    headers: {
      Authorization: header,
    },
    data: null,
  });
  return req.then((res) => res.data);
};

const getProfile = async (header, profileId) => {
  const req = axios({
    method: "GET",
    url: `${API_BASE_URL}/api/profile/${profileId}`,
    headers: {
      Authorization: header,
    },
    data: null,
  });
  return req.then((res) => res.data);
};

const createProfile = async (header, profile) => {
  const {
    name,
    biography,
    style,
    textcolor = "#ffffff",
    bgcolor = "#000000",
    font = "Arial",
  } = profile;
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/profile`,
    headers: {
      Authorization: header,
    },
    data: { name, biography, style, textcolor, bgcolor, font },
  });
  return req.then((res) => res.data);
};

const editProfile = async (header, profileId, profile) => {
  const {
    name,
    biography,
    style,
    textcolor = "#ffffff",
    bgcolor = "#000000",
    font = "Arial",
  } = profile;
  const req = axios({
    method: "PUT",
    url: `${API_BASE_URL}/api/profile/${profileId}`,
    headers: {
      Authorization: header,
    },
    data: { name, biography, style, textcolor, bgcolor, font },
  });
  return req.then((res) => res.data);
};

const deleteProfile = async (header, profileId) => {
  const req = axios({
    method: "DELETE",
    url: `${API_BASE_URL}/api/profile/${profileId}`,
    headers: {
      Authorization: header,
    },
    data: null,
  });
  return req.then((res) => res.data);
};

/* POSTS */

const getPosts = async (header, page = 1) => {
  const req = axios({
    method: "GET",
    url: `${API_BASE_URL}/api/posts/${page}`,
    headers: {
      Authorization: header,
    },
    data: null,
  });
  return req.then((res) => res.data);
};

const getPost = async (header, postId) => {
  const req = axios({
    method: "GET",
    url: `${API_BASE_URL}/api/post/${postId}`,
    headers: {
      Authorization: header,
    },
    data: null,
  });
  return req.then((res) => res.data);
};

const createPost = async (header, post) => {
  const { story, url } = post;
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/post/`,
    headers: {
      Authorization: header,
    },
    data: {
      story,
      url,
    },
  });
  return req.then((res) => res.data);
};

const editPost = async (header, postId, post) => {
  const {
    title,
    subtitle,
    story,
    lang,
    supplementary,
    url,
    profile,
    short,
    long,
    blog,
    reel,
  } = post;
  const req = axios({
    method: "PUT",
    url: `${API_BASE_URL}/api/post/${postId}`,
    headers: {
      Authorization: header,
    },
    data: {
      title,
      subtitle,
      story,
      lang,
      supplementary,
      url,
      profileId: profile ? profile.id : null,
      short,
      long,
      blog,
      reel,
    },
  });
  return req.then((res) => res.data);
};

const deletePost = async (header, postId) => {
  const req = axios({
    method: "DELETE",
    url: `${API_BASE_URL}/api/post/${postId}`,
    headers: { Authorization: header },
    data: null,
  });
  return req.then((res) => res.data);
};

/* FETCH */

const fetchUrl = async (header, url) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/fetch/url`,
    headers: { Authorization: header },
    data: { url },
  });
  return req.then((res) => res.data);
};

const fetchSearch = async (header, keywords) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/fetch/search`,
    headers: { Authorization: header },
    data: { keywords },
  });
  return req.then((res) => res.data);
};

const fetchRss = async (header, keywords, category) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/fetch/rss`,
    headers: { Authorization: header },
    data: { keywords, category },
  });
  return req.then((res) => res.data);
};

/* GENERATE */

const generateText = async (header, postId, type) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/generate/text/${postId}`,
    headers: { Authorization: header },
    data: { type },
  });
  return req.then((res) => res.data);
};

const generateImage = async (header, postId) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/generate/image/${postId}`,
    headers: { Authorization: header },
    data: {},
  });
  return req.then((res) => res.data);
};

const generateAudience = async (header, postId) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/generate/audience/${postId}`,
    headers: { Authorization: header },
    data: {},
  });
  return req.then((res) => res.data);
};

const generateQuickImage = async (header, prompt, size) => {
  const req = axios({
    method: "POST",
    url: `${API_BASE_URL}/api/generate/quick/image`,
    headers: { Authorization: header },
    data: { prompt: prompt, size: size },
  });
  return req.then((res) => res.data);
};

/* SUBSCRIPTION */

const subscriptionStatus = async (header) => {
  const req = axios({
    method: "GET",
    url: `${API_BASE_URL}/api/subscription/status`,
    headers: { Authorization: header },
    data: null,
  });
  return req.then((res) => res.data);
};

export default {
  login,
  register,
  changePassword,
  getProfiles,
  getProfile,
  createProfile,
  editProfile,
  deleteProfile,
  getPosts,
  getPost,
  createPost,
  editPost,
  deletePost,
  fetchUrl,
  fetchSearch,
  fetchRss,
  generateText,
  generateImage,
  generateAudience,
  generateQuickImage,
  subscriptionStatus,
};
