import React from "react";
import { Spinner } from "@material-tailwind/react";

function Loading() {
  return (
    <div className='absolute right-4 bottom-6 items-center w-fit bg-green-400 shadow-md rounded-md p-2'>
      <Spinner className='h-8 w-8 text-white' />
    </div>
  );
}

export default Loading;
