import React, { useEffect, useState } from "react";
import ModalBlock from "../../components/ModalBlock";
import { SelectFrame } from "../../components/FormElements";
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryButtonWithIconOnTop,
} from "../../components/Buttons";

import {
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  NewspaperIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

const ContentTypeModal = ({ postModalPage, setPostModalPage }) => {
  // Store the temporary selected state and resets it when the modal is closed
  const [selected, setSelected] = useState(postModalPage);
  useEffect(() => setSelected(0), [postModalPage]);

  return (
    <ModalBlock
      openStatus={postModalPage === 1}
      title="Select a content creation option"
      width="lg"
      primaryButton={
        <PrimaryButton onClick={() => setPostModalPage(selected)}>
          Continue
        </PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton onClick={() => setPostModalPage(0)}>
          Close
        </SecondaryButton>
      }
    >
      <SelectFrame title="Select Post Type" required={true}>
        <div className="flex flex-row gap-4">
          <SecondaryButtonWithIconOnTop
            icon={<NewspaperIcon className="h-5 w-5" />}
            width="w-full"
            height="h-20"
            onClick={() => setSelected(2)}
            active={selected === 2}
          >
            News Search
          </SecondaryButtonWithIconOnTop>{" "}
          <SecondaryButtonWithIconOnTop
            icon={<DocumentMagnifyingGlassIcon className="h-5 w-5" />}
            width="w-full"
            height="h-20"
            onClick={() => setSelected(3)}
            active={selected === 3}
          >
            Web Search
          </SecondaryButtonWithIconOnTop>{" "}
          <SecondaryButtonWithIconOnTop
            icon={<DocumentTextIcon className="h-5 w-5" />}
            width="w-full"
            height="h-20"
            onClick={() => setSelected(4)}
            active={selected === 4}
          >
            URL Article
          </SecondaryButtonWithIconOnTop>{" "}
          <SecondaryButtonWithIconOnTop
            icon={<PencilIcon className="h-5 w-5" />}
            width="w-full"
            height="h-20"
            onClick={() => setSelected(5)}
            active={selected === 5}
          >
            Own Story
          </SecondaryButtonWithIconOnTop>{" "}
        </div>
      </SelectFrame>
    </ModalBlock>
  );
};

export default ContentTypeModal;
