import React, { useState } from "react";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { Typography } from "@material-tailwind/react";
import { PrimaryButton } from "../components/Buttons";
import { Input } from "../components/FormElements";
import service from "../service";
import Message from "../components/Message";
import Loading from "../components/Loading";
import { BasicHeader } from "../components/PageHeaderTypes";

function Settings() {
  const user = useAuthUser();
  const auth = useAuthHeader();

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Change password handler
  const changePasswordHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .changePassword(auth(), currentPassword, password, passwordConfirmation)
      .then((data) => {
        setMessage("Successfully changed password");
        setLoading(false);
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  return (
    <div className="max-w-2xl">
      <BasicHeader title="👤 User" />

      <Typography className="font-normal mt-6">
        Username: {user().username}
      </Typography>

      <Typography className="font-normal">Email: {user().email}</Typography>

      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      <hr className="my-8" />

      <Typography variant="h6" className="my-4">
        Change Password
      </Typography>

      <div className="flex flex-col gap-4">
        <Input
          placeholder="********"
          required={true}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        >
          Current Password
        </Input>

        <Input
          placeholder="********"
          required={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        >
          New Password
        </Input>

        <Input
          placeholder="********"
          required={true}
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        >
          Confirm New Password
        </Input>

        <PrimaryButton size="sm" onClick={() => changePasswordHandler()}>
          Change Password
        </PrimaryButton>
      </div>
    </div>
  );
}

export default Settings;
