import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/outline";

import { BasicHeader } from "../components/PageHeaderTypes";
import {
  GhostButton,
  PrimaryButton,
  TertiaryButton,
} from "../components/Buttons";
import service from "../service";
import Loading from "../components/Loading";
import Message from "../components/Message";

import ContentTypeModal from "./modals/ContentTypeModal";
import StoryTextModal from "./modals/StoryTextModal";
import FetchURLModal from "./modals/FetchURLModal";
import FetchSearchModal from "./modals/FetchSearchModal";
import FetchRSSModal from "./modals/FetchRSSModal";
import { Table } from "../components/Table";

function Posts() {
  const auth = useAuthHeader();
  const { page } = useParams();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState();

  // 0 - closed, 1 - type selection, 2 - fetch rss, 3 - fetch search, 4 - fetch URL, 5 - story text
  const [postModalPage, setPostModalPage] = useState(0);
  const [post, setPost] = useState(); // New post object

  // Fetch all posts for given page
  useEffect(() => {
    setLoading(true);
    service
      .getPosts(auth(), page)
      .then((data) => {
        setPosts(data.posts);
        setPagination(data.pagination);
        if (data.posts.length === 0) {
          setMessage("No posts found.");
        }
        setLoading(false);
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  }, [page]);

  // Delete post handler
  const deletePostHandler = async (postId) => {
    setLoading(true);
    service
      .deletePost(auth(), postId)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  return (
    <>
      {/* Header Bar */}
      <BasicHeader
        title="📝 Posts"
        actionItem={
          <PrimaryButton
            righticon={<PlusIcon className="w-4 h-4" />}
            onClick={() => setPostModalPage(1)}
          >
            Create Post
          </PrimaryButton>
        }
      />

      {/* Content Creation Modals */}
      <ContentTypeModal
        postModalPage={postModalPage}
        setPostModalPage={setPostModalPage}
      />
      <FetchRSSModal
        postModalPage={postModalPage}
        setPostModalPage={setPostModalPage}
        post={post}
        setPost={setPost}
      />
      <FetchSearchModal
        postModalPage={postModalPage}
        setPostModalPage={setPostModalPage}
        post={post}
        setPost={setPost}
      />
      <FetchURLModal
        postModalPage={postModalPage}
        setPostModalPage={setPostModalPage}
        post={post}
        setPost={setPost}
      />
      <StoryTextModal
        postModalPage={postModalPage}
        setPostModalPage={setPostModalPage}
        post={post}
        setPost={setPost}
      />

      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      {/* Pagination */}
      {pagination && pagination.pageCount != 0 && (
        <div className="flex flex-row gap-2 mt-4 pb-10 items-center">
          <Typography className="font-normal">
            Showing page {pagination.page} of {pagination.pageCount}
          </Typography>
          {pagination.page > 1 && (
            <TertiaryButton
              onClick={() => {
                navigate(`/posts/${pagination.page - 1}`);
              }}
            >
              Previous
            </TertiaryButton>
          )}
          {pagination.page < pagination.pageCount && (
            <TertiaryButton
              onClick={() => {
                navigate(`/posts/${pagination.page + 1}`);
              }}
            >
              Next
            </TertiaryButton>
          )}
        </div>
      )}

      {posts.length === 0 && (
        <Typography className="font-normal" color="blue-gray">
          Create a post to get started
        </Typography>
      )}

      {/* Posts Table */}
      {posts.length > 0 && (
        <Table
          data={posts.map((post) => ({
            1: (
              <div className="flex flex-row gap-4">
                <img
                  src={post.image ? post.image : "/postdummy.png"}
                  alt="Post"
                  className="rounded-md w-14 h-14"
                />
                <div className="flex flex-col gap-1">
                  <Typography className="font-semibold text-sm">
                    {post.title}
                  </Typography>
                  <Typography className="font-normal text-xs">
                    {`${post.story.substring(0, 150)}...`}
                  </Typography>
                </div>
              </div>
            ),

            2: post.createdAt.substring(0, 10),
            3: post.profile ? post.profile.name : "-",

            4: (
              <div className="flex flex-row justify-start gap-3 items-center text-center">
                <Link to={`/post/${post.id}`}>
                  <GhostButton
                    variant="outlined"
                    className="flex items-center gap-2"
                    size="sm"
                  >
                    <PencilSquareIcon className="h-4 w-4" />
                  </GhostButton>
                </Link>
                <GhostButton
                  variant="outlined"
                  className="border-red-600 text-red-600 flex items-center gap-2"
                  onClick={() => deletePostHandler(post.id)}
                  size="sm"
                >
                  <TrashIcon className="h-4 w-4 text-red-600" />
                </GhostButton>
              </div>
            ),
          }))}
          tableMetaData={[
            {
              column: 1,
              minWidth: "600px",
              width: "80%",
              maxWidth: "700px",
              customName: "Post",
            },
            {
              column: 2,
              minWidth: "150px",

              customName: "Date",
              textProperties: "text-gray-700 font-normal",
            },
            {
              column: 3,
              minWidth: "120px",
              customName: "Profile",
              textProperties: "text-gray-700 font-normal",
            },

            {
              column: 4,
              customName: "Actions",
              minWidth: "100px",
              textProperties: "text-gray-500 font-normal",
            },
          ]}
        />
      )}
    </>
  );
}

export default Posts;
