import React, { useEffect, useState } from "react";
import { useNavigate, Link, Form } from "react-router-dom";
import { Card, Typography, Button } from "@material-tailwind/react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import service from "../service";
import Message from "../components/Message";
import Loading from "../components/Loading";
import { Input } from "../components/FormElements";

function Login() {
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Redirect to home if already logged in
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/home");
    }
  }, []);

  // Login handler
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    service
      .login(identifier, password)
      .then((data) => {
        signIn({
          token: data.jwt,
          expiresIn: 24 * 60,
          tokenType: "Bearer",
          authState: data.user,
        });
        navigate("/home");
      })
      .catch((err) => {
        if (err.response) {
          setMessage(err.response.data.error.message);
        } else {
          setMessage(err.message);
        }
        setLoading(false);
      });
  };

  // Login form
  return (
    <div className="justify-center flex flex-column h-screen w-full align-middle items-center content-center bg-transparent bg-green-100">
      <Card
        className="items-center px-20 py-8 bg-white shadow-md w-fit self-center"
        shadow={false}
      >
        <img
          src="/sitelogoblack500.png"
          className="w-8 h-auto z-1 mb-4"
          alt="background.png"
        />
        <Typography variant="h4" color="blue-gray">
          Viralizard AI
        </Typography>

        <Typography color="gray" className="mt-1 font-normal">
          Login with your account credentials
        </Typography>

        <form
          className="mt-8 mb-2 w-full max-w-screen-lg sm:w-96 sm:pr-4 sm:pl-4"
          onSubmit={handleLogin}
        >
          {message ? <Message message={message} /> : null}
          {loading ? <Loading /> : null}

          <div className="mb-1 flex flex-col gap-2">
            <Input
              size="lg"
              placeholder="Email or Username"
              minLength={3}
              className="bg-gray-200 border-none focus:border-none p-2 text-center"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />

            <Input
              type="password"
              placeholder="********"
              minLength={8}
              size="lg"
              className="bg-gray-200 border-none focus:border-none p-2 text-center"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Button type="submit" className="mt-6" fullWidth>
            Login
          </Button>
        </form>

        <Typography color="gray" className="mt-4 text-center font-normal">
          Do not have an account?{" "}
          <Link to="/register" className="font-semibold text-black">
            Register
          </Link>
        </Typography>
        <a href="/tcs.pdf" className="font-normal text-xs mt-4">
          By continuing, you agree to our Terms and Conditions.
        </a>
      </Card>
    </div>
  );
}

export default Login;
