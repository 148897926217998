import { HomeIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useState } from "react";

export function Table({
  data = [
    {
      Icon: <HomeIcon className='h-5 w-5' />,
      "Post Name": "First Post",
      "Post Description":
        "From footballers to singers, this month’s AI or real quiz is sure to leaving you scratching your head!Challenge yourself to get full marks in our latest AI or real quiz and see how you do at spotting pictures that have been created using artificial intelligence.And don’t forget, do your own research to find out where the original images and videos came from, verify sources, and check to see if credible news sites have covered the story.",
      "Created Date": "2024-01-25",
    },
    {
      Icon: <HomeIcon className='h-5 w-5' />,
      "Post Name": "<h1>/h1>",
      "Post Description": "Description for the second post goes here.",
      "Created Date": "2024-01-26",
    },
  ],
  actionItem,

  tableMetaData = [
    {
      column: 1,
      textProperties: "text-gray-800 font-medium",
      customName: "Icon New",
    },
    {
      column: 2,
      minWidth: "120px",
      customName: "Name New",
      textProperties: "text-gray-800 font-medium",
    },
    {
      column: 3,
      minWidth: "400px",
      width: "50%",
      maxWidth: "600px",
      customName: "Hello Description",
      textProperties: "text-gray-700 font-normal",
    },
    {
      column: 4,
      customName: "Hello Date",
      minWidth: "100px",
      textProperties: "text-gray-500 font-normal",
      customRender: (rowData) => (
        <>
          <button className='text-blue-500 underline mr-2'>
            Open in New Tab
          </button>
          <button className='text-blue-500 underline'>Select</button>
        </>
      ),
    },
    {
      column: 5,
      customName: "Hello Actions",
      minWidth: "150px",
      textProperties: "text-gray-400 font-normal",
      customRender: (rowData) => (
        <>
          <button className='text-blue-500 underline mr-2'>
            Open in New Tab
          </button>
          <button className='text-blue-500 underline'>Select</button>
        </>
      ),
    },
  ],
}) {
  // Get all unique column names
  const columnNames = Array.from(
    new Set(data.flatMap((row) => Object.keys(row)))
  );

  return (
    <div style={{ overflowX: "auto" }}>
      <table className='min-w-full divide-y-0'>
        <colgroup>
          {tableMetaData.map((column) => (
            <col
              key={column.column}
              style={{
                width: column.width,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
              }}
            />
          ))}
          {actionItem && (
            <col
              style={{
                width: `auto`,
                minWidth: "auto",
              }}
            />
          )}
        </colgroup>

        <thead className=''>
          <tr className='border-b-[1.6px] border-gray-700'>
            {tableMetaData.map((column) => {
              const columnName = column.customName || column.column;

              return (
                <th
                  key={column.column}
                  scope='col'
                  className='pr-6 py-3 text-left text-xs font-semibold  text-gray-700 tracking-wider '
                >
                  {columnName}
                </th>
              );
            })}
            {actionItem && (
              <th
                scope='col'
                className='pr-6 py-3 text-left text-xs font-semibold  text-gray-700  tracking-wider'
              >
                Actions
              </th>
            )}
          </tr>
        </thead>

        <tbody className=' divide-y divide-gray-100'>
          {data.map((row, index) => (
            <tr key={index}>
              {columnNames.map((column, columnIndex) => (
                <td
                  key={column}
                  className={`text-sm pr-6 py-5 ${
                    tableMetaData[columnIndex]?.textProperties || ""
                  } text-top`} // Add the 'text-top' class here
                >
                  {tableMetaData[columnIndex]?.customRender ? (
                    tableMetaData[columnIndex]?.customRender(row)
                  ) : column === "PostName" &&
                    typeof row[column] === "string" &&
                    row[column].startsWith("<") ? (
                    <div dangerouslySetInnerHTML={{ __html: row[column] }} />
                  ) : typeof row[column] === "string" &&
                    row[column].split(" ").length <= 30 ? (
                    row[column]
                  ) : typeof row[column] === "string" ? (
                    row[column].split(" ").slice(0, 30).join(" ").concat(" ...")
                  ) : (
                    row[column]
                  )}
                </td>
              ))}
              <td
                className={
                  actionItem
                    ? tableMetaData[tableMetaData.length - 1]?.textProperties ||
                      ""
                    : tableMetaData[4]?.textProperties || ""
                }
              >
                {actionItem}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
