import React, { useState } from "react";
import {
  Typography,
  Menu,
  MenuHandler,
  MenuItem,
  ListItem,
  MenuList,
} from "@material-tailwind/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { TextArea } from "../../components/FormElements";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../components/Buttons";
import ImageCanvas from "../components/ImageCanvas";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

function GeneratePostForm({
  post,
  setPost,
  generateTextHandler,
  generateImageHandler,
  generateAudienceHandler,
  editPostHandler,
}) {
  const [selectedType, setSelectedType] = useState(0);

  const types = [
    {
      type: "short",
      name: "Short Post",
      desc: "Short Social Media Post (e.g. Twitter, Weibo Posts)",
      maxLength: 500,
      value: post.short,
    },
    {
      type: "long",
      name: "Long Post",
      desc: "Long Social Media Post (e.g. Facebook, LinkedIn Posts)",
      maxLength: 10000,
      value: post.long,
    },
    {
      type: "blog",
      name: "Blog Post",
      desc: "Blog Post (e.g. Wordpress, Medium, LinkedIn Articles)",
      maxLength: 50000,
      value: post.blog,
    },
    {
      type: "reel",
      name: "Reels Video Script",
      desc: "Reels Video Script (e.g. TikTok, YouTube Shorts, Instagrem Reels)",
      maxLength: 5000,
      value: post.reel,
    },
  ];

  // Copy to clipboard function
  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <div className="flex flex-col 2xl:flex-row gap-8 w-full h-full overflow-scroll-y py-8 ">
      {/* Left Sidebar */}
      <div className="flex flex-col gap-4 lg:min-w-[350px] lg:w-3/12">
        <Typography variant="h6" color="blue-gray" className="">
          🌅 Post Image
        </Typography>

        <div className="flex flex-row gap-2">
          <PrimaryButton onClick={() => generateImageHandler()}>
            Generate Image
          </PrimaryButton>
          <SecondaryButton onClick={() => window.open(post.image, "_blank")}>
            Download Original
          </SecondaryButton>
        </div>

        <ImageCanvas
          actualSize={1920}
          styleSize={300}
          imageUrl={post.image ? post.image : "/postdummy.png"}
          title={post.title}
          subtitle={post.subtitle}
          bgcolor={post.profile?.bgcolor}
          textcolor={post.profile?.textcolor}
          font={post.profile?.font}
        />
      </div>

      {/* Middle Content Section */}
      <div className="flex flex-col w-full gap-2 lg:w-6/12">
        <Menu placement="bottom-start">
          <MenuHandler>
            <ListItem
              className="bg-transparent hover:bg-transparent focus:bg-transparent enabled:bg-transparent focus:text-black w-fit px-0 py-0 "
              ripple={false}
            >
              <Typography variant="h6" color="blue-gray" className="pr-2">
                <span>📃 {types[selectedType].name}</span>
              </Typography>
              <ChevronUpDownIcon className="h-4 w-4" />
            </ListItem>
          </MenuHandler>
          <MenuList>
            {types.map(({ name }, index) => (
              <MenuItem
                key={name}
                onClick={() => setSelectedType(index)}
                ripple={false}
              >
                <Typography className="font-normal text-black text-xs">
                  {name}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        <Typography className="font-normal text-xs">
          {types[selectedType].desc}
        </Typography>

        {types.map(({ name, type, value, maxLength }, index) => {
          if (index === selectedType) {
            return (
              <>
                <div className="flex flex-row gap-2 py-1 items-center">
                  <PrimaryButton onClick={() => generateTextHandler(type)}>
                    Generate {name}
                  </PrimaryButton>

                  <TertiaryButton onClick={() => editPostHandler()}>
                    Save
                  </TertiaryButton>

                  <SecondaryButton onClick={() => copyToClipboard(value)}>
                    Copy
                  </SecondaryButton>
                </div>
                <TextArea
                  className={`w-full h-[100%] min-h-[550px] bg-transparent focus:border-gray-100 -mt-1`}
                  placeholder={
                    "✍️ Write your content here or let our AI generate content for you using the button above.\n\n💡 If you specified a profile, we'll take their biography and wring style into account.\n\n💾 When you're done, remember to click Save."
                  }
                  value={value}
                  maxLength={maxLength}
                  onChange={(e) => setPost({ ...post, [type]: e.target.value })}
                />
              </>
            );
          }
        })}
      </div>

      {/* Right Sidebar */}
      <div className="flex flex-col gap-3 lg:min-w-[350px] lg:w-3/12">
        <Typography variant="h6" color="blue-gray" className="">
          🧑🏽‍🤝‍🧑🏿 Audience Targeting
        </Typography>

        <Typography variant="h6" className="text-sm text-gray-700 font-medium">
          Analyze the story to find the most optimal audience.
        </Typography>

        <PrimaryButton
          onClick={() => generateAudienceHandler()}
          className="mt-2 mb-4"
        >
          Analyze
        </PrimaryButton>
        {post.audience && (
          <>
            <div className="flex flex-row gap-4 items-center">
              <div className="flex flex-row gap-2 w-[100px] items-center">
                <CheckCircleIcon className="h-5 w-5 text-green-600" />
                <Typography variant="h6" className="text-sm">
                  Age:
                </Typography>
              </div>
              <Typography
                variant="h6"
                className="text-sm text-gray-700 font-normal"
              >
                {post.audience?.age}
              </Typography>
            </div>

            <div className="flex flex-row gap-4 items-center">
              <div className="flex flex-row gap-2 w-[100px] items-center">
                <CheckCircleIcon className="h-5 w-5 text-green-600" />
                <Typography variant="h6" className="text-sm">
                  Gender:
                </Typography>
              </div>
              <Typography
                variant="h6"
                className="text-sm text-gray-700 font-normal"
              >
                {post.audience?.sex}
              </Typography>
            </div>

            <div className="flex flex-row gap-4 items-start">
              <div className="flex flex-row gap-2 min-w-[100px] items-center">
                <CheckCircleIcon className="h-5 w-5 text-green-600" />
                <Typography variant="h6" className="text-sm">
                  Group:
                </Typography>
              </div>
              <Typography
                variant="h6"
                className="text-sm text-gray-700 font-normal"
              >
                {post.audience?.group.join(", ")}
              </Typography>
            </div>

            <div className="flex flex-row gap-4 items-start">
              <div className="flex flex-row gap-2 min-w-[100px] items-center">
                <XCircleIcon className="h-5 w-5 text-red-600" />
                <Typography variant="h6" className="text-sm">
                  Dislike:
                </Typography>
              </div>
              <Typography
                variant="h6"
                className="text-sm text-gray-700 font-normal"
              >
                {post.audience?.dislike}
              </Typography>
            </div>

            <Typography variant="h6" className="text-sm mt-2">
              #️⃣ Hashtags
            </Typography>

            <Typography
              variant="h6"
              className="text-sm text-gray-700 font-normal"
            >
              {post.audience?.hashtags.map((hashtag) => `#${hashtag} `)}
            </Typography>
          </>
        )}

        <hr className="my-4" />

        <Typography variant="h6" className="text-lg">
          📽️ Film Reels
        </Typography>
        <Typography className="text-sm text-gray-700 font-medium">
          Your reels video script will be transferred to the teleprompter for
          filming.
        </Typography>

        <div className="flex flex-row gap-4 items-center my-2">
          <Link to="/teleprompter" state={{ text: post?.reel }}>
            <PrimaryButton>Enter Teleprompter</PrimaryButton>
          </Link>
          <SecondaryButton onClick={() => setSelectedType(3)}>
            Edit Script
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}

export default GeneratePostForm;
