import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import Loading from "../../components/Loading";
import Message from "../../components/Message";
import ModalBlock from "../../components/ModalBlock";
import { Input } from "../../components/FormElements";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { readArticle } from "../ragUtil";

function FetchURLModal({ postModalPage, setPostModalPage, post, setPost }) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch URL handler, save story text then go to modal
  const fetchUrlHandler = async () => {
    setLoading(true);
    setMessage("");
    try {
      const article = await readArticle(post.url);
      setPost({ ...post, story: article });
      setPostModalPage(5);
    } catch {
      setMessage("Failed to read URL article.");
    }
    setLoading(false);
  };

  // Fetch URL form layout
  return (
    <ModalBlock
      openStatus={postModalPage === 4}
      title="Fetch URL Article"
      width="lg"
      primaryButton={
        <PrimaryButton onClick={() => fetchUrlHandler()}>Fetch</PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton
          onClick={() => {
            setPost(null);
            setPostModalPage(0);
          }}
        >
          Close
        </SecondaryButton>
      }
    >
      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      <Input
        value={post && post.url}
        onChange={(e) => setPost({ ...post, url: e.target.value })}
        placeholder="https://bbc.com/article-123456"
        required={true}
      >
        Web Article Link
      </Input>
      <Typography className="font-normal text-xs mt-1">
        If the article is behind a paywall or firewall, you may need to manually
        copy the content and create the post using story text instead.
      </Typography>
    </ModalBlock>
  );
}

export default FetchURLModal;
