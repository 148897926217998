import React, { useEffect, useState } from "react";
import { TextArea } from "../components/FormElements";
import { useAuthHeader } from "react-auth-kit";
import { Typography } from "@material-tailwind/react";
import { PrimaryButton } from "../components/Buttons";
import Message from "../components/Message";
import Loading from "../components/Loading";
import service from "../service";
import { BasicHeader } from "../components/PageHeaderTypes";

export default function QuickImage() {
  const auth = useAuthHeader();

  const [size, setSize] = useState("1024x1024");
  const [style, setStyle] = useState("Not specified");
  const [colorTone, setColorTone] = useState("Not specified");
  const [lighting, setLighting] = useState("Not specified");
  const [background, setBackground] = useState("Not specified");
  const [composition, setComposition] = useState("Not specified");
  const [desc, setDesc] = useState();

  const [prompt, setPrompt] = useState("");
  useEffect(() => {
    setPrompt(
      `Create an image based on the details below. Image description: ${desc}, image style: ${style}, image composition:${composition} image color and tone: ${colorTone}, image lighting effect: ${lighting}, background: ${background}, 8K, high detail, super quality. No Text or Logo.`
    );
  }, [desc, style, colorTone, lighting]);
  const [image, setImage] = useState();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Generate image handler
  const generateQuickImageHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .generateQuickImage(auth(), prompt, size)
      .then((data) => {
        setImage(data.image);
        setLoading(false);
        setMessage(
          `Generated image successfully: please wait for image to load`
        );
      })
      .catch((e) => {
        setMessage(
          "Image generation failed, please check your prompt and try again"
        );
        setLoading(false);
      });
  };

  return (
    <>
      <BasicHeader title="🌅 Quick Image" />
      <div className="flex flex-col lg:flex-row gap-8 w-full mt-4">
        <div className="flex flex-col gap-2 min-w-[350px]">
          {message ? <Message message={message} /> : null}
          {loading ? <Loading /> : null}

          <TextArea
            fieldHeight={`h-40`}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
            maxLength="2500"
          >
            Image Description
          </TextArea>
          <Typography
            variant="paragraph"
            className="text-sm font-medium font-outfit mb-1 text-gray-800"
          >
            Image Ratio
          </Typography>
          <select
            value={size}
            onChange={(e) => setSize(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
          >
            <option value="1024x1024">Square (1024x1024)</option>
            <option value="1792x1024">Landscape (1792x1024)</option>
            <option value="1024x1792">Portrait (1024x1792)</option>
          </select>
          <Typography
            variant="paragraph"
            className="text-sm font-medium font-outfit mb-1 text-gray-800"
          >
            Image Style
          </Typography>
          <select
            id="image-style"
            name="image-style"
            value={style}
            onChange={(e) => setStyle(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
          >
            <option value="Not specified">Not Specified</option>
            <option value="Chinese style Cute Cartoon">
              Chinese style Cute Cartoon
            </option>
            <option value="Cute Cartoon">Cute Cartoon</option>
            <option value="Bokeh Effect">Bokeh Effect</option>
            <option value="Cinematic">Cinematic</option>
            <option value="Digital Art">Digital Art</option>
            <option value="Painting">Painting</option>
            <option value="Pixelated">Pixelated</option>
            <option value="Cyberpunk">Cyberpunk</option>
            <option value="Minimalist">Minimalist</option>
            <option value="Retro">Retro</option>
            <option value="Neon">Neon</option>
            <option value="Pop Art">Pop Art</option>
          </select>
          <Typography
            variant="paragraph"
            className="text-sm font-medium font-outfit mb-1 text-gray-800"
          >
            Image Color and Tone
          </Typography>
          <select
            value={colorTone}
            onChange={(e) => setColorTone(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
          >
            <option value="Not specified">Not Specified</option>
            <option value="Black and White">Black and White</option>
            <option value="Cool Tone">Cool Tone</option>
            <option value="Golden">Golden</option>
            <option value="Monochromatic">Monochromatic</option>
            <option value="Muted Color">Muted Color</option>
            <option value="Pastel Color">Pastel Color</option>
            <option value="Toned Image">Toned Image</option>
            <option value="Vibrant Colors">Vibrant Colors</option>
            <option value="Warm Tone">Warm Tone</option>
          </select>

          <Typography
            variant="paragraph"
            className="text-sm font-medium font-outfit mb-1 text-gray-800"
          >
            Image Lighting
          </Typography>

          <select
            value={lighting}
            onChange={(e) => setLighting(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
          >
            <option value="Not specified">Not Specified</option>

            <option value="Backlighting">Backlighting</option>
            <option value="Ray Tracing">Ray Tracing</option>
            <option value="Dramatic Light">Dramatic Light</option>
            <option value="Golden Hour">Golden Hour</option>
            <option value="Harsh Light">Harsh Light</option>
            <option value="Long-time Exposure">Long-time Exposure</option>
            <option value="Low Lighting">Low Lighting</option>
            <option value="Multiexposure">Multiexposure</option>
            <option value="Studio Light">Studio Light</option>
            <option value="Surreal Lighting">Surreal Lighting</option>
          </select>
          <Typography
            variant="paragraph"
            className="text-sm font-medium font-outfit mb-1 text-gray-800"
          >
            Image composition
          </Typography>
          <select
            value={composition}
            onChange={(e) => setComposition(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
          >
            <option value="Not specified">Not Specified</option>
            <option value="Normal Centered Object">
              Normal Centered Object
            </option>
            <option value="Closeup">Closeup</option>
            <option value="Landscape Photography">Landscape Photography</option>
            <option value="Macro Photography">Macro Photography</option>
            <option value="Photographed Through Window">
              Photographed Through Window
            </option>
            <option value="Shallow Depth of Field">
              Shallow Depth of Field
            </option>
            <option value="Shot From Above">Shot From Above</option>
            <option value="Shot From Below">Shot From Below</option>
            <option value="Surface Detail">Surface Detail</option>
            <option value="Wide Angle">Wide Angle</option>
            <option value="No">No</option>
          </select>
          <Typography
            variant="paragraph"
            className="text-sm font-medium font-outfit mb-1 text-gray-800"
          >
            Background Type
          </Typography>
          <select
            value={background}
            onChange={(e) => setBackground(e.target.value)}
            className="border border-gray-300 focus:border-2 focus:border-gray-400 rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400"
          >
            <option value="Not specified">Not Specified</option>
            <option value="Pure White Background">
              Pure White Background (exp.)
            </option>
            <option value="Blur Background">Blur Background</option>
            <option value="Gradient Background">Gradient Background</option>
            <option value="Patterned Background">Patterned Background</option>
            <option value="Plain Background">Plain Background</option>
            <option value="Textured Background">Textured Background</option>
          </select>

          <PrimaryButton onClick={generateQuickImageHandler} className="mt-4">
            Generate Image
          </PrimaryButton>
        </div>
        <div>
          <img
            src={image ? image : "/postdummy.png"}
            alt="Generated Image"
            className="w-96 rounded-md"
          />
          {image && (
            <PrimaryButton
              onClick={() => window.open(image, "_blank")}
              className="mt-4"
            >
              Download Original
            </PrimaryButton>
          )}
        </div>
      </div>
    </>
  );
}
