import React, { useCallback, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../components/Buttons";
import { BasicHeader } from "../components/PageHeaderTypes";
import { useLocation } from "react-router-dom";
import { Input } from "../components/FormElements";
import { Radio, Typography } from "@material-tailwind/react";

export default function Reels() {
  const location = useLocation();

  /* Webcam Recorder */
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  // Video constraints
  const constraintsPortrait = {
    aspectRatio: 1080 / 1920,
    facingMode: "user",
    width: 200,
  };
  const constraintsLandscape = {
    aspectRatio: 1920 / 1080,
    facingMode: "user",
    width: 400,
  };
  const constraintsSquare = {
    aspectRatio: 1,
    facingMode: "user",
    width: 300,
  };
  const [videoConstraints, setVideoConstraints] = useState(constraintsPortrait);

  // Detect supported video format for browser
  const getSupportedFormat = () => {
    const formats = ["webm", "mp4"];
    return formats.find((format) =>
      MediaRecorder.isTypeSupported(`video/${format}`)
    );
  };

  // Save recorded chunks
  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  // Start recording
  const handleStartCapture = useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: `video/${getSupportedFormat()}`,
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  // Stop recording
  const handleStopCapture = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  // Download video
  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: `video/${getSupportedFormat()}`,
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `reelsvideo.${getSupportedFormat()}`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }, [recordedChunks]);

  /* Teleprompter */
  const scrollRef = useRef(null);
  const intervalRef = useRef(null);
  const [text, setText] = useState(location.state?.text || "");
  const [speed, setSpeed] = useState(100);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    stopScroll();
    if (scrolling) {
      const scrollHeight = scrollRef.current.scrollHeight;
      const clientHeight = scrollRef.current.clientHeight;
      const scrollable = scrollHeight - clientHeight;
      let current = 0;
      const scroll = () => {
        current += 1;
        if (current <= scrollable) {
          scrollRef.current.scrollTo(0, current);
        } else {
          stopScroll();
          setScrolling(false);
        }
      };
      intervalRef.current = setInterval(scroll, speed);
    }
  }, [scrolling]);

  const stopScroll = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  // Automatically stop scroll on leave
  useEffect(() => {
    return () => {
      stopScroll();
    };
  }, [location]);

  return (
    <>
      <BasicHeader title="📽️ Teleprompter" />
      <div className="flex flex-col justify-center items-center">
        {/* Teleprompter */}
        <textarea
          ref={scrollRef}
          className="text-xl h-40 w-full max-w-xl overflow-auto text-center bg-transparent"
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          placeholder="Paste your script here, or open a post to transfer the prepared script"
        />

        {/* Webcam Recorder */}
        <Webcam
          width={videoConstraints.width}
          audio={true}
          muted={true}
          mirrored={true}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          className="rounded-lg shadow-lg my-8"
        />

        {/* Teleprompter Controls */}
        <div className="flex flex-row items-center gap-4 mb-4">
          {scrolling ? (
            <TertiaryButton
              onClick={() => setScrolling(false)}
              className="mb-2"
            >
              Stop Scrolling
            </TertiaryButton>
          ) : (
            <>
              <PrimaryButton
                onClick={() => setScrolling(true)}
                className="mb-2"
              >
                Start Scrolling
              </PrimaryButton>
              {/* Speed Selection */}
              Speed
              <input
                type="range"
                min="10"
                max="200"
                value={speed}
                onChange={(e) => setSpeed(e.target.value)}
                className="w-48"
              />
              <Input
                type="number"
                value={speed}
                onChange={(e) => setSpeed(e.target.value)}
                className="!w-20"
              />
            </>
          )}
        </div>

        {/* Recording Controls */}
        <div className="flex flex-row items-center gap-4 mb-4">
          {capturing ? (
            <TertiaryButton onClick={handleStopCapture}>
              Stop Recording
            </TertiaryButton>
          ) : (
            <>
              <PrimaryButton onClick={handleStartCapture}>
                Start Recording
              </PrimaryButton>
              {recordedChunks.length > 0 && (
                <SecondaryButton onClick={handleDownload}>
                  Download Video
                </SecondaryButton>
              )}

              {/* Orientation Selection */}
              <Radio
                color="green"
                name="videoConstraints"
                defaultChecked={true}
                onChange={() => setVideoConstraints(constraintsPortrait)}
                label={
                  <Typography className="font-normal text-sm">
                    Portrait
                  </Typography>
                }
              />
              <Radio
                color="green"
                name="videoConstraints"
                onChange={() => setVideoConstraints(constraintsLandscape)}
                label={
                  <Typography className="font-normal text-sm">
                    Landscape
                  </Typography>
                }
              />
              <Radio
                color="green"
                name="videoConstraints"
                onChange={() => setVideoConstraints(constraintsSquare)}
                label={
                  <Typography className="font-normal text-sm">
                    Square
                  </Typography>
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
