import React, { useRef, useEffect, useState } from "react";
import { GhostButton } from "../../components/Buttons";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { Checkbox, Typography, Radio } from "@material-tailwind/react";

const ImageCanvas = ({
  imageUrl,
  title,
  subtitle,
  bgcolor = "#000000",
  textcolor = "#ffffff",
  font = "Arial, Sans-Serif",
}) => {
  const canvasRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [size, setSize] = useState({ width: 1920, height: 1920 });

  // Image and canvas size
  const actualWidth = size.width;
  const actualHeight = size.height;
  const styleWidth = 350;
  const styleHeight = (actualHeight / actualWidth) * styleWidth;

  // Helper function to split text into multiple lines
  const splitTextIntoLines = (text, maxWidth, context) => {
    let lines = [];
    let currentLine = "";
    if (text) {
      const words = text.split(" ");

      words.forEach((word) => {
        const testLine = currentLine + word + " ";
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth) {
          lines.push(currentLine);
          currentLine = word + " ";
        } else {
          currentLine = testLine;
        }
      });
      lines.push(currentLine);
    }

    return lines;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Set the canvas border
    const image = new Image();
    image.src = imageUrl;
    image.setAttribute("crossorigin", "anonymous");
    image.onload = () => {
      canvas.width = actualWidth;
      canvas.height = actualHeight;
      canvas.style.width = `${styleWidth}px`;
      canvas.style.height = `${styleHeight}px`;

      // Draw the image
      context.drawImage(
        image,
        0,
        (actualHeight - actualWidth) / 2,
        actualWidth,
        actualWidth
      );

      if (showOverlay) {
        // Draw the gradient overlay
        const gradient = context.createLinearGradient(0, 0, 0, actualHeight);
        gradient.addColorStop(0, `${bgcolor}00`);
        gradient.addColorStop(1, `${bgcolor}`);
        context.fillStyle = gradient;
        context.fillRect(0, 0, actualWidth, actualHeight);

        // Settings for the text
        const titleLineHeight = 160;
        const subtitleLineHeight = 120;
        context.fillStyle = textcolor;

        const maxWidth = actualWidth * 0.9;
        const titleFont = `bold 130px ${font}`;
        const subtitleFont = `90px ${font}`;

        // Split the title text
        context.font = titleFont;
        const titleLines = splitTextIntoLines(title, maxWidth, context);

        // Split the subtitle text
        context.font = subtitleFont;
        const subtitleLines = splitTextIntoLines(subtitle, maxWidth, context);

        // Starting positions for the title and subtitle
        const startYTitle =
          actualHeight -
          titleLines.length * titleLineHeight -
          subtitleLines.length * subtitleLineHeight;
        const startYSubtitle =
          startYTitle + titleLines.length * titleLineHeight;

        // Print the title text
        context.font = titleFont;
        titleLines.forEach((line, index) => {
          context.fillText(
            line,
            actualWidth * 0.05,
            startYTitle + index * titleLineHeight
          );
        });

        // Print the subtitle text
        context.font = subtitleFont;
        subtitleLines.forEach((line, index) => {
          context.fillText(
            line,
            actualWidth * 0.05,
            startYSubtitle + index * subtitleLineHeight
          );
        });
      }
    };
  }, [imageUrl, title, subtitle, bgcolor, textcolor, font, showOverlay, size]);

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "canvas_image.png";
    link.click();
  };

  return (
    <>
      <div className="relative w-fit h-fit mt-4">
        <canvas className="z-0 rounded-lg" ref={canvasRef} />
        <GhostButton
          righticon={<ArrowDownIcon className="h-4 2-4 p-0 " />}
          className="absolute bottom-2 right-2 z-10 bg-white opacity-60"
          onClick={handleDownload}
        />
      </div>
      <div className="flex flex-row gap-4">
        <Typography className="font-semibold text-sm mt-4 pb-4">
          Ratio
        </Typography>
        <Radio
          color="green"
          name="size"
          defaultChecked={true}
          onChange={() => setSize({ width: 1920, height: 1920 })}
          label={<Typography className="font-normal text-sm">1:1</Typography>}
        />
        <Radio
          color="green"
          name="size"
          onChange={() => setSize({ width: 1920, height: 1440 })}
          label={<Typography className="font-normal text-sm">4:3</Typography>}
        />
        <Radio
          color="green"
          name="size"
          onChange={() => setSize({ width: 1920, height: 1280 })}
          label={<Typography className="font-normal text-sm">3:2</Typography>}
        />
      </div>
      <Checkbox
        checked={showOverlay}
        onChange={() => setShowOverlay(!showOverlay)}
        className=""
        label={
          <>
            <Typography className="font-semibold text-sm">
              Show text overlay with profile styles
            </Typography>
          </>
        }
      />
      <Typography className="font-normal text-sm mt-1 text-gray-700">
        To specify the font, text or background colors, create a profile and
        select it in Edit Details. You can also change the titles and subtitles
        there.
      </Typography>
    </>
  );
};

export default ImageCanvas;
