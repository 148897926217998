import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import "./Tailwind.css";
import { AuthProvider } from "react-auth-kit";

import App from "./App";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Settings from "./auth/Settings";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Profiles from "./profile/Profiles";
import Posts from "./post/Posts";
import EditPost from "./post/EditPost";
import DesignPage from "./pages/Design";
import Teleprompter from "./tools/Teleprompter";
import QuickImage from "./tools/QuickImage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider authType="cookie" authName="_auth">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/home"
              element={
                <App>
                  <Home />
                </App>
              }
            />
            <Route
              path="/settings"
              element={
                <App>
                  <Settings />
                </App>
              }
            />
            <Route
              path="/profiles"
              element={
                <App>
                  <Profiles />
                </App>
              }
            />
            <Route
              path="/posts/:page?"
              element={
                <App>
                  <Posts />
                </App>
              }
            />
            <Route
              path="/post/:postId"
              element={
                <App>
                  <EditPost />
                </App>
              }
            />
            <Route
              path="/teleprompter"
              element={
                <App>
                  <Teleprompter />
                </App>
              }
            />

            <Route
              path="/quickimage"
              element={
                <App>
                  <QuickImage />
                </App>
              }
            />
            <Route
              path="/design"
              element={
                <App>
                  <DesignPage />
                </App>
              }
            />
            <Route
              path="*"
              element={
                <App>
                  <NotFound />
                </App>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
