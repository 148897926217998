import React from "react";
import ModalBlock from "../../components/ModalBlock";
import { TextArea, Input, ColorPicker } from "../../components/FormElements";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../components/Buttons";
import Message from "../../components/Message";
import Loading from "../../components/Loading";
import FontSelector from "./FontSelector";

function ProfileModal({
  profile,
  setProfile,
  handler,
  message,
  loading,
  openStatus,
  setOpenStatus,
  deleteProfileHandler,
}) {
  function setBiographyTemplate() {
    setProfile({
      ...profile,
      biography: `Education: [ major ] at [ university ]
Work history: [ job title ] at [ company ]
Professional interests: [ interest 1 ] and [ interest 2]
Hobbies: [ hobby 1 ] and [ hobby 2 ]
Political opinion: [ issues ]`,
    });
  }

  return (
    <ModalBlock
      openStatus={openStatus}
      title="Profile"
      width="lg"
      primaryButton={<PrimaryButton onClick={handler}>Save</PrimaryButton>}
      secondaryButton={
        <>
          <SecondaryButton onClick={() => setOpenStatus(false)}>
            Cancel
          </SecondaryButton>
          {deleteProfileHandler && (
            <TertiaryButton
              className="bg-red-500 text-white"
              onClick={() => deleteProfileHandler(profile.id)}
            >
              Delete
            </TertiaryButton>
          )}
        </>
      }
    >
      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}
      <div className="flex flex-col gap-8 w-full lg:flex-row">
        <div className="flex flex-col w-full gap-2">
          <Input
            required={true}
            placeholder="John Doe"
            maxLength={50}
            value={profile.name}
            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
          >
            Profile Name
          </Input>
          <TextArea
            required={false}
            fieldHeight="h-40"
            maxLength={5000}
            value={profile.biography}
            onChange={(e) =>
              setProfile({ ...profile, biography: e.target.value })
            }
            actionItem={
              <TertiaryButton onClick={setBiographyTemplate}>
                Use Template
              </TertiaryButton>
            }
            placeholder="Describe the profile's background, e.g. education, work history, professional interests, hobbies, and political opinion."
          >
            Biography
          </TextArea>
          <TextArea
            fieldHeight="h-20"
            maxLength={5000}
            value={profile.style}
            onChange={(e) => setProfile({ ...profile, style: e.target.value })}
            placeholder="Describe or provide writing samples of the profile's writing style, e.g. prior social media post content."
          >
            Writing Style
          </TextArea>
        </div>
        <div className="flex flex-col min-w-[250px] gap-2">
          <ColorPicker
            color={profile.textcolor ? profile.textcolor : "#ffffff"}
            setColor={(color) => setProfile({ ...profile, textcolor: color })}
          >
            Text Color
          </ColorPicker>
          <ColorPicker
            color={profile.bgcolor ? profile.bgcolor : "#000000"}
            setColor={(color) => setProfile({ ...profile, bgcolor: color })}
          >
            Background Color
          </ColorPicker>
          <FontSelector
            font={profile.font ? profile.font : "Arial"}
            setFont={(font) => setProfile({ ...profile, font: font })}
          />
        </div>
      </div>
    </ModalBlock>
  );
}

export default ProfileModal;
