import React from "react";
import { Option, Select, Typography } from "@material-tailwind/react";

function FontSelector({ font, setFont }) {
  const fonts = [
    "Arial",
    "Verdana",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Times New Roman",
    "Courier New",
    "Garamond",
    "Brush Script MT",
  ];

  return (
    <div className="my-4">
      <Typography
        variant="paragraph"
        className="text-sm font-medium font-outfit mb-1 text-gray-800"
      >
        Text Font
      </Typography>
      <Select
        className="border border-blue-gray-300 focus:border-gray-700"
        labelProps={{ className: "before:content-none after:content-none" }}
        value={font}
        onChange={(f) => setFont(f)}
      >
        {fonts.map((f) => (
          <Option key={f} value={f}>
            {f}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default FontSelector;
