import React from "react";
import LanguageSelector from "../components/LanguageSelector";
import ProfileSelector from "../components/ProfileSelector";
import ModalBlock from "../../components/ModalBlock";
import { Input, TextArea } from "../../components/FormElements";

const PostDetailForm = ({
  post,
  setPost,
  modalOpenState = true,
  actionItem,
}) => {
  return (
    <ModalBlock
      openStatus={modalOpenState}
      title="Post Details"
      width="xl"
      primaryButton={actionItem}
    >
      <div className="flex flex-col gap-4 w-full lg:flex-row gap-8">
        <div className="flex flex-col w-full lg:w-3/4 gap-2">
          <Input
            required={true}
            value={post?.title ?? ""}
            onChange={(e) => setPost({ ...post, title: e.target.value })}
            placeholder="Required. Will appear as the headline of your post image."
          >
            Post Title
          </Input>
          <Input
            required={false}
            value={post?.subtitle ?? ""}
            placeholder="Optional. If provided will appear as secondary line of your post image."
            onChange={(e) => setPost({ ...post, subtitle: e.target.value })}
          >
            Post Subtitle
          </Input>
          <TextArea
            required={true}
            fieldHeight="h-60"
            maxLength={10000}
            value={post?.story ?? ""}
            onChange={(e) => setPost({ ...post, story: e.target.value })}
            placeholder="Examples: news story, a personal achievement, or an informational topic"
          >
            Post Story
          </TextArea>
        </div>
        <div className="flex flex-col w-full lg:w-1/4 lg:pl-4 gap-2">
          <LanguageSelector post={post} setPost={setPost} />
          <ProfileSelector post={post} setPost={setPost} />
          <TextArea
            maxLength={5000}
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            fieldHeight="h-40"
            value={post?.supplementary ?? ""}
            onChange={(e) =>
              setPost({ ...post, supplementary: e.target.value })
            }
            placeholder="Examples: specific things you want to include in your post, your personal viewpoints, or any additional info or instructions"
          >
            Supplementary Instructions
          </TextArea>
          <Input
            required={false}
            maxLength={50}
            value={post?.url ?? ""}
            onChange={(e) => setPost({ ...post, url: e.target.value })}
            placeholder="https://bbc.com/article-123456"
          >
            Attachment Link
          </Input>
        </div>
      </div>
    </ModalBlock>
  );
};

export default PostDetailForm;
