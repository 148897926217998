import React, { useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Select, Option, Typography } from "@material-tailwind/react";
import service from "../../service";

function ProfileSelector({ post, setPost }) {
  const auth = useAuthHeader();

  const [profiles, setProfiles] = useState();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  // Fetch all profiles for selection
  useEffect(() => {
    setLoading(true);
    service
      .getProfiles(auth())
      .then((data) => {
        setProfiles(data.profiles);
        if (data.profiles.length === 0) {
          setMessage("No profiles found.");
        }
        setLoading(false);
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full">
      <Typography
        variant="paragraph"
        className="text-sm font-medium font-outfit my-1 text-gray-800"
      >
        Profile (for generation and styles)
      </Typography>
      {profiles && (
        <Select
          className="border border-gray-300  focus:border-2 focus:border-gray-400 "
          labelProps={{ className: "before:content-none after:content-none" }}
          value={post.profile ? post.profile.id : null}
          onChange={(id) => setPost({ ...post, profile: { id } })}
        >
          {profiles.map((profile) => (
            <Option value={profile.id}>{profile.name}</Option>
          ))}
        </Select>
      )}
      {loading && (
        <Typography className="text-xs font-normal">Loading...</Typography>
      )}
      {message && (
        <Typography className="text-xs font-normal">{message}</Typography>
      )}
    </div>
  );
}

export default ProfileSelector;
