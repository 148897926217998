import React, { useState, useEffect } from "react";
import { Card, Button, Typography } from "@material-tailwind/react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";
import service from "../service";
import Message from "../components/Message";
import Loading from "../components/Loading";
import { Input } from "../components/FormElements";

function Register() {
  const isAuthenticated = useIsAuthenticated();
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Redirect to home if already logged in
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/home");
    }
  }, []);

  // Register handler
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    service
      .register(username, email, password)
      .then((data) => {
        signIn({
          token: data.jwt,
          expiresIn: 30 * 24 * 60,
          tokenType: "Bearer",
          authState: data.user,
        });
        navigate("/home");
      })
      .catch((err) => {
        if (err.response) {
          setMessage(err.response.data.error.message);
        } else {
          setMessage(err.message);
        }
        setLoading(false);
      });
  };

  // Registration form
  return (
    <div className="justify-center flex flex-column h-screen w-full align-middle items-center content-center bg-transparent bg-green-100">
      <Card
        className="items-center px-20 py-8 bg-white shadow-md w-fit self-center"
        color="transparent"
        shadow={false}
      >
        <img src="/sitelogoblack500.png" className="w-8 h-auto z-1 mb-4" />
        <Typography variant="h4" color="blue-gray">
          Viralizard AI
        </Typography>

        <Typography color="gray" className="mt-1 font-normal">
          Register for a free trial account
        </Typography>

        <form
          className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 sm:pr-4 sm:pl-4"
          onSubmit={handleRegister}
        >
          {message ? <Message message={message} /> : null}
          {loading ? <Loading /> : null}

          <div className="mb-1 flex flex-col gap-2">
            <Input
              size="lg"
              placeholder="Username"
              minLength={3}
              className="bg-gray-200 border-none focus:border-none p-2 text-center"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <Input
              size="lg"
              type="email"
              placeholder="Email"
              className="bg-gray-200 border-none focus:border-none p-2 text-center"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              type="password"
              size="lg"
              placeholder="Password"
              minLength={8}
              className="bg-gray-200 border-none focus:border-none p-2 text-center"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Button className="mt-6" fullWidth type="submit">
            Register
          </Button>

          <Typography color="gray" className="mt-4 text-center font-normal">
            Already have an account?{" "}
            <Link to="/" className="font-medium text-gray-900">
              Login
            </Link>
          </Typography>
        </form>
        <a href="/tcs.pdf" className="font-normal text-xs mt-4">
          By continuing, you agree to our Terms and Conditions.
        </a>
      </Card>
    </div>
  );
}

export default Register;
