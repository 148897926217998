// Component for a basic header with a title
import React from "react";
import { Typography } from "@material-tailwind/react";

export function BasicHeader({ title, previousTitle, afterTitle, actionItem }) {
  let titleColor = "text-black";

  if (afterTitle && !previousTitle) {
    titleColor = "text-gray-600";
  } else if (!afterTitle && previousTitle) {
    titleColor = "text-green-500";
  }

  return (
    <div className="flex flex-row justify-between">
      <div>
        <Typography variant="h1" className={`text-2xl font-outfit`}>
          {`${previousTitle ? previousTitle + " /" : ""} `}
          <span className={`${titleColor}`}>{title}</span>

          <span className="text-green-800">{`${
            afterTitle ? " / " + afterTitle : ""
          } `}</span>
        </Typography>
      </div>

      <div className="flex flex-row gap-4">{actionItem}</div>
    </div>
  );
}

// Component for a header with a logo and a title
export function LogoHeader({ logo, title }) {
  return (
    <header>
      <img src={logo} alt="Logo" />
      <h1>{title}</h1>
    </header>
  );
}

// Component for a header with a navigation menu
export function MenuHeader({ title, menuItems }) {
  return (
    <header>
      <h1>{title}</h1>
      <nav>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
