import React, { useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
  Button,
  Collapse,
} from "@material-tailwind/react";
import { PrimaryButton } from "../components/Buttons";
import SubscriptionBox from "../auth/SubscriptionBox";

function Home() {
  const [openProfile, setOpenProfile] = useState(false);
  const [openTheme, setOpenTheme] = useState(false);
  const [openPost, setOpenPost] = useState(false);

  return (
    <>
      <div className="my-4">
        <Typography variant="h3">Kickstart your influencer journey.</Typography>
      </div>

      <SubscriptionBox />

      <div className="flex flex-col">
        <Typography variant="h5" className="mb-6">
          Get Started
        </Typography>
        <Timeline>
          <TimelineItem>
            <TimelineConnector />
            <TimelineHeader className="h-3">
              <TimelineIcon />
              <Typography variant="h6" className="leading-none">
                🤩 Setup Profiles
              </Typography>
            </TimelineHeader>
            <TimelineBody className="pb-8">
              <Typography className="font-normal text-sm text-gray-700 mt-2">
                Start by creating a compelling profile that reflects your
                identity.
              </Typography>
              <div className="flex flex-row gap-2 mt-4">
                <PrimaryButton
                  size="sm"
                  onClick={() => setOpenProfile((cur) => !cur)}
                >
                  Learn More
                </PrimaryButton>

                <a href="/profiles">
                  <Button size="sm" variant="outlined">
                    Go
                  </Button>
                </a>
              </div>
              <Collapse open={openProfile}>
                <Typography className="text-sm font-black mt-4 text-gray-600">
                  Name
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  A name to identify the profile.
                </Typography>
                <Typography className="text-sm font-black mt-4 text-gray-600">
                  Biography
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Write a brief biography to introduce yourself to our AI
                  engine. Share your personal background, interests, political
                  opinion, and any relevant information.
                </Typography>
                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Writing Style
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Describe or provide a writing sample, such as your previous
                  social media posts. Our AI can then learn to write in your
                  writing style to create more engaging content for your
                  audience.
                </Typography>
                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Text and Background Colors
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Choose the text and background colors for your posts. Our AI
                  will use these colors to create visual content for your posts.
                </Typography>
              </Collapse>
            </TimelineBody>
          </TimelineItem>

          <TimelineItem>
            <TimelineConnector />
            <TimelineHeader className="h-3">
              <TimelineIcon />
              <Typography variant="h6" className="leading-none">
                📝 Setup Posts
              </Typography>
            </TimelineHeader>
            <TimelineBody className="pb-8">
              <Typography className="font-normal text-sm text-gray-700 mt-2">
                Find the most relevant piece of story from a variety of sources
                to write about.
              </Typography>
              <div className="flex flex-row gap-2 mt-4">
                <PrimaryButton
                  variant="LearnMore"
                  size="sm"
                  onClick={() => setOpenTheme((cur) => !cur)}
                >
                  Learn More
                </PrimaryButton>
                <a href="/posts">
                  <Button size="sm" variant="outlined">
                    Go
                  </Button>
                </a>
              </div>
              <Collapse open={openTheme}>
                <Typography className="text-sm font-black mt-4 text-gray-600">
                  News Trend
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Choose keywords and news category that best describe the
                  topics you want to write about. Our AI will help you find the
                  most relevant news stories.
                </Typography>
                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Web Search
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  For more informationmal topics, use our AI to search the web
                  for the most relevant stories.
                </Typography>
                <Typography className="font-black text-sm mt-4 text-gray-600">
                  URL Article
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  If you already have a piece of web aticle to share, use our AI
                  to extract the content for you.
                </Typography>
                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Own Story
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Share your personal story with our AI and let it help you
                  craft a compelling narrative.
                </Typography>
              </Collapse>
            </TimelineBody>
          </TimelineItem>

          <TimelineItem>
            <TimelineHeader className="h-3">
              <TimelineIcon />
              <Typography variant="h6" className="leading-none">
                🔮 Generate Content
              </Typography>
            </TimelineHeader>

            <TimelineBody className="pb-8">
              <Typography className="font-normal text-sm text-gray-700 mt-2">
                Generate cross-platform text, image and video content for your
                social media.
              </Typography>
              <div className="flex flex-row gap-2 mt-4">
                <PrimaryButton
                  variant="LearnMore"
                  size="sm"
                  onClick={() => setOpenPost((cur) => !cur)}
                >
                  Learn More
                </PrimaryButton>
                <a href="/posts">
                  <Button size="sm" variant="outlined">
                    Go
                  </Button>
                </a>
              </div>

              <Collapse open={openPost}>
                <Typography className="text-sm font-black mt-4 text-gray-600">
                  Text Content
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Use our AI to generate text content for your social media,
                  including short text, long text, and blog posts.
                </Typography>

                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Image Content
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Using your story, our AI can generate image content overlaid
                  with post titles and the styles of the profile.
                </Typography>

                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Video Content
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Use our AI to generate reels video script and film it using
                  our teleprompting functionality.
                </Typography>

                <Typography className="font-black text-sm mt-4 text-gray-600">
                  Analyze Audience
                </Typography>
                <Typography className="font-normal text-sm text-gray-700 mt-2">
                  Analyze the story to find the most optimal audience, including
                  age group, gender, and interest.
                </Typography>
              </Collapse>
            </TimelineBody>
          </TimelineItem>
        </Timeline>
      </div>
    </>
  );
}

export default Home;
