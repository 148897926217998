import React from "react";
import { Typography } from "@material-tailwind/react";
import { CompactPicker } from "react-color";

export const Input = ({
  children,
  required,
  value,
  onChange,
  onBlur,
  fieldHeight,
  placeholder,
  minLength,
  maxLength,
  ariaLabel,
  className,
  type,
}) => {
  return (
    <div>
      <Typography
        variant='paragraph'
        className='text-sm font-medium font-outfit mb-1 text-gray-800'
      >
        {children}
        {required && <span className='text-red-500'> *</span>}
      </Typography>
      <input
        type={type}
        className={`${fieldHeight} text-gray-900 font-normal text-sm align-text-top w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        aria-label={ariaLabel}
      />
    </div>
  );
};
export const Option = ({ value, children, className }) => {
  return (
    <option
      value={value}
      className={`text-gray-900 border ${className} `}
    >
      {children}
    </option>
  );
};

export const Select = ({
  children,
  required,
  value,
  onChange,
  onBlur,
  placeholder,
  ariaLabel,
  className,
  label,
}) => {
  return (
    <div>
      <Typography
        variant='paragraph'
        className='text-sm font-medium font-outfit mb-1 text-gray-800'
      >
        {label}
        {required && <span className='text-red-500'> *</span>}
      </Typography>
      <select
        className={`text-gray-900 font-normal text-sm align-text-top w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        placeholder={placeholder}
        aria-label={ariaLabel}
      >
        {children}
      </select>
    </div>
  );
};

export const SelectOption = ({ value, children }) => {
  return (
    <option
      value={value}
      className='text-gray-900'
    >
      {children}
    </option>
  );
};

export const TextArea = ({
  children,
  required,
  value,
  onChange,
  onBlur,
  fieldHeight,
  placeholder,
  minLength,
  maxLength,
  ariaLabel,
  actionItem,
  className,
}) => {
  return (
    <div>
      <div className='flex flex-row justify-between items-center'>
        <Typography
          variant='paragraph'
          className='text-sm font-medium font-outfit mb-1 text-gray-800'
        >
          {children}
          {required && <span className='text-red-500'> *</span>}
        </Typography>
        {actionItem}
      </div>
      <textarea
        type='text'
        minLength={minLength}
        maxLength={maxLength}
        aria-label={ariaLabel}
        className={`${fieldHeight} text-gray-900 text-sm font-normal align-text-top w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 ${className}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        placeholder={placeholder}
        aria-required={required}
      />
    </div>
  );
};

export const SelectFrame = ({ title, required, children }) => {
  return (
    <div>
      <div className='flex flex-row justify-between items-center mb-1'>
        <Typography
          variant='paragraph'
          className='text-sm font-medium font-outfit mb-1 text-gray-800'
        >
          {title}
          {required && <span className='text-red-500'> *</span>}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export const ColorPicker = ({ color, setColor, children }) => {
  return (
    <div className='h-100'>
      <Typography
        variant='paragraph'
        className='text-sm font-medium font-outfit my-4 text-gray-800'
      >
        {children}
      </Typography>
      <CompactPicker
        color={color}
        onChangeComplete={(color) => setColor(color.hex)}
      />
    </div>
  );
};
