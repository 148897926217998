import { Typography, Card } from "@material-tailwind/react";
import React from "react";

function FooterBox() {
  return (
    <Card className="p-4 w-full rounded-md gap-2 sm:hidden" shadow={false}>
      <Typography className="font-semibold text-xs">
        &copy; Viralizard AI 2023-2024.
      </Typography>
      <a href="/tcs.pdf" className="font-normal text-xs mb-4">
        Terms and Conditions
      </a>
      <Typography className="font-normal text-xs mb-2">
        Cyberport CCMF (Sep 2023)
      </Typography>
      <img src="/cyberportlogo.png" alt="Cyberport Logo" className="w-full" />
    </Card>
  );
}

export default FooterBox;
