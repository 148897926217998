import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import service from "../service";
import Loading from "../components/Loading";
import Message from "../components/Message";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import { BasicHeader } from "../components/PageHeaderTypes";
import PostDetailModal from "./modals/PostDetailModal";
import GeneratePostForm from "./modals/GeneratePostForm";

function EditPost() {
  const navigate = useNavigate();
  const auth = useAuthHeader();

  const [post, setPost] = useState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  // Check current window link if it consists of /#details at the end
  useEffect(() => {
    if (window.location.href.includes("/#details")) {
      setModalOpen(true);
    }
  }, []);

  // Fetch post with id, redirect to posts if not found
  const { postId } = useParams();
  useEffect(() => {
    setLoading(true);
    service
      .getPost(auth(), postId)
      .then((data) => {
        setPost(data.post);
        setLoading(false);
      })
      .catch(() => {
        navigate("/posts");
      });
  }, []);

  // Edit post save handler
  const editPostHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .editPost(auth(), postId, post)
      .then((data) => {
        setPost(data.post);
        setLoading(false);
        setModalOpen(false);
        setMessage("Post saved successfully");
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  // Generate text handler
  const generateTextHandler = async (type) => {
    setLoading(true);
    setMessage("");
    service
      .generateText(auth(), postId, type)
      .then((data) => {
        setPost(data.post);
        setLoading(false);
        setMessage(`Generated ${type} text successfully`);
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  // Generate image handler
  const generateImageHandler = async (type) => {
    setLoading(true);
    setMessage("");
    service
      .generateImage(auth(), postId, type)
      .then((data) => {
        setPost(data.post);
        setLoading(false);
        setMessage(
          "Generated image successfully: please wait for image to load"
        );
      })
      .catch((e) => {
        setMessage("Image generation failed");
        setLoading(false);
      });
  };

  // Generate ads handler
  const generateAudienceHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .generateAudience(auth(), postId)
      .then((data) => {
        setPost(data.post);
        setLoading(false);
        setMessage("Generated audience targeting successfully");
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  // Edit profile form layout
  return (
    <>
      <BasicHeader
        title="📝 Edit Post"
        actionItem={
          <>
            <SecondaryButton onClick={() => setModalOpen(true)}>
              Edit Details
            </SecondaryButton>
            <PrimaryButton onClick={() => editPostHandler()}>
              Save
            </PrimaryButton>
          </>
        }
      />

      {message && <Message message={message} />}
      {loading && <Loading />}

      {post && (
        <>
          <PostDetailModal
            post={post}
            setPost={setPost}
            modalOpenState={modalOpen}
            actionItem={
              <div className="flex flex-row gap-2">
                <PrimaryButton onClick={() => editPostHandler()}>
                  Save
                </PrimaryButton>
              </div>
            }
          />
          <GeneratePostForm
            post={post}
            setPost={setPost}
            generateTextHandler={generateTextHandler}
            generateImageHandler={generateImageHandler}
            generateAudienceHandler={generateAudienceHandler}
            editPostHandler={editPostHandler}
          />
        </>
      )}
    </>
  );
}

export default EditPost;
