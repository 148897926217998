import { useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

import service from "../../service";
import Loading from "../../components/Loading";
import Message from "../../components/Message";

import ModalBlock from "../../components/ModalBlock";
import { Input, TextArea } from "../../components/FormElements";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";

function StoryTextModal({ postModalPage, setPostModalPage, post, setPost }) {
  const auth = useAuthHeader();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Create post handler, redirect to edit post after success
  const createPostHandler = () => {
    setLoading(true);
    setMessage("");
    service
      .createPost(auth(), post)
      .then((data) => {
        navigate(`/post/${data.post.id}/#details`);
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  // Create post form layout
  return (
    <ModalBlock
      openStatus={postModalPage === 5}
      title="Create Post"
      width="lg"
      primaryButton={
        <PrimaryButton onClick={() => createPostHandler()}>
          Create Post
        </PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton
          onClick={() => {
            setPostModalPage(0);
            setPost(null);
          }}
        >
          Close
        </SecondaryButton>
      }
    >
      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      <TextArea
        required={true}
        fieldHeight="h-40"
        maxLength={1000}
        value={post && post.story}
        onChange={(e) => setPost({ ...post, story: e.target.value })}
        placeholder="Examples: news story, a personal achievement, or an informational topic"
      >
        Post Story
      </TextArea>

      <Input
        required={false}
        maxLength={50}
        value={post && post.url}
        onChange={(e) => setPost({ ...post, url: e.target.value })}
        placeholder="https://bbc.com/article-123456"
      >
        Attachment Link
      </Input>

      <Typography className="font-normal text-xs mt-2" color="blue-gray">
        Optional. If provided will be saved for later sharing use.
      </Typography>
    </ModalBlock>
  );
}

export default StoryTextModal;
