import React from "react";

import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Menu,
  MenuHandler,
  MenuList,
  Button,
} from "@material-tailwind/react";

import {
  HomeIcon,
  UserIcon,
  VideoCameraIcon,
  PowerIcon,
  DocumentChartBarIcon,
  Cog6ToothIcon,
  Bars3Icon,
  PhotoIcon,
} from "@heroicons/react/24/outline";

import {
  HomeIcon as HomeIconFilled,
  UserIcon as UserIconFilled,
  VideoCameraIcon as VideoCameraIconFilled,
  DocumentChartBarIcon as DocumentChartBarIconFilled,
  Cog6ToothIcon as Cog6ToothIconFilled,
  PhotoIcon as PhotoIconFilled,
} from "@heroicons/react/24/solid";

import { useSignOut } from "react-auth-kit";
import { useNavigate, useLocation } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const location = useLocation();
  const mobileNavButtonClass =
    "bg-white p-4 w-auto text-md border font-normal text-semibold normal-case -mr-3 hover:border-gray-50 rounded-full gap-2 flex flex-row align-middle items-center";

  const currentPath = location.pathname;

  const NavBarMenuItem = [
    {
      title: "Home",
      icon: <HomeIcon className="h-5 w-5" />,
      activeIcon: <HomeIconFilled className="h-5 w-5" color="#26C180" />,
      link: "/home",
    },
    {
      title: "Profiles",
      icon: <UserIcon className="h-5 w-5" />,
      activeIcon: <UserIconFilled className="h-5 w-5" color="#26C180" />,
      link: "/profiles",
    },
    {
      title: "Posts",
      icon: <DocumentChartBarIcon className="h-5 w-5" />,
      activeIcon: (
        <DocumentChartBarIconFilled className="h-5 w-5" color="#26C180" />
      ),
      link: "/posts",
    },
    {
      title: "Teleprompter",
      icon: <VideoCameraIcon className="h-5 w-5" />,
      activeIcon: <VideoCameraIconFilled className="h-5 w-5" color="#26C180" />,
      link: "/teleprompter",
    },
    {
      title: "Quick Image",
      icon: <PhotoIcon className="h-5 w-5" />,
      activeIcon: <PhotoIconFilled className="h-5 w-5" color="#26C180" />,
      link: "/quickimage",
    },
    {
      title: "Settings",
      icon: <Cog6ToothIcon className="h-5 w-5" />,
      activeIcon: <Cog6ToothIconFilled className="h-5 w-5" color="#26C180" />,
      link: "/settings",
    },
  ];

  // Logout handler and return to login page
  const logoutHandler = () => {
    signOut();
    navigate("/");
  };

  // Navbar layout
  return (
    <>
      {/* Mobile Nav Menu */}
      <div
        className={` absolute flex flex-row gap-5 w-full justify-between md:hidden p-4 bg-white z-10`}
      >
        <div className="flex flex-row gap-3">
          <img
            src="/sitelogoblack500.png"
            className="align-middle object-fit w-8 h-8 self-center bg-transparent"
            alt="sitelogo"
          />
          <Typography
            variant="h5"
            color="blue-gray"
            className="self-center align-middle text-lg font-semibold mb-[-0.5px]"
          >
            Viralizard AI
          </Typography>
        </div>
        <Menu className="rounded md:hidden" placement="bottom-end">
          <MenuHandler>
            <Button
              className="p-2 elf-end rounded-full border-none active:border-none focus-within:shadow-none focus:border-none shadow-none hover:shadow-none focus:shadow-none bg-transparent text-gray-700 "
              ripple={false}
              size="sm"
            >
              <Bars3Icon className="h-5 w-5" />
            </Button>
          </MenuHandler>
          <MenuList className="md:hidden shadow-none mt-4 flex flex-col border-none gap-4 bg-transparent text-center text-semibold flex-wrap content-end justify-end justify-items-end align-bottom border-2 border-black">
            <Button
              className={mobileNavButtonClass}
              onClick={() => navigate("/home")}
            >
              <HomeIcon className="h-5 w-5" />
              Home
            </Button>
            <Button
              className={mobileNavButtonClass}
              onClick={() => navigate("/profiles")}
            >
              <UserIcon className="h-5 w-5" />
              Profiles
            </Button>
            <Button
              className={mobileNavButtonClass}
              onClick={() => navigate("/posts")}
            >
              <DocumentChartBarIcon className="h-5 w-5" />
              Posts
            </Button>
            <Button
              className={mobileNavButtonClass}
              onClick={() => navigate("/user")}
            >
              <Cog6ToothIcon className="h-5 w-5" />
              Settings
            </Button>
            <Button
              className="p-4 text-md border w-auto font-normal text-semibold normal-case -mr-3 border-red-400 bg-red-400 text-white rounded-full gap-2 flex flex-row align-middle items-center shadow-xl"
              onClick={logoutHandler}
            >
              <PowerIcon className="h-5 w-5" />
              Logout
            </Button>
          </MenuList>
        </Menu>
      </div>

      {/* Desktop Nav Menu */}
      <Card className={`w-full gap-0 sm:hidden`} shadow={false}>
        <div className="p-4 flex flex-row gap-2 w-full justify-between flex-wrap sm:p-2">
          <div className="flex flex-row gap-3">
            <img
              src="/sitelogoblack500.png"
              className="align-middle object-fit w-8 h-8 self-center bg-transparent"
              alt="sitelogo"
            />
            <Typography
              color="blue-gray"
              className="font-outfit self-center align-middle text-md font-semibold mb-[-0.5px]"
            >
              Viralizard AI
            </Typography>
          </div>
        </div>

        <List className="visible sm:hidden mt-4">
          {NavBarMenuItem.map((item, index) => (
            <>
              <ListItem
                key={index}
                onClick={() => navigate(item.link)}
                className={`${
                  currentPath === item.link
                    ? "bg-blue-gray-50 text-gray-900 w-[200px] "
                    : "bg-white w-[200px]"
                }`}
              >
                <ListItemPrefix className="pl-1">
                  {currentPath === item.link ? item.activeIcon : item.icon}
                </ListItemPrefix>
                <Typography
                  className={`${
                    currentPath === item.link
                      ? "font-outfit font-medium text-sm -ml-1"
                      : "font-outfit font-normal text-sm -ml-1"
                  }`}
                >
                  {item.title}
                </Typography>
              </ListItem>
            </>
          ))}

          <ListItem onClick={logoutHandler} className="bg-white w-[200px]">
            <ListItemPrefix className="pl-1">
              <PowerIcon className="h-5 w-5" />
            </ListItemPrefix>
            <Typography className={"font-outfit font-normal text-sm -ml-1"}>
              Logout
            </Typography>
          </ListItem>
        </List>
      </Card>
    </>
  );
}

export default Navbar;
