import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";

import service from "../service";
import ProfileModal from "./components/ProfileModal";

function EditProfile({ profileId, setProfileId, deleteProfileHandler }) {
  const navigate = useNavigate();
  const auth = useAuthHeader();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState({
    name: "",
    biography: "",
    style: "",
    textcolor: "#ffffff",
    bgcolor: "#000000",
  });

  // Fetch profile every time profileId changes
  useEffect(() => {
    setProfile({
      name: "",
      biography: "",
      style: "",
      textcolor: "#ffffff",
      bgcolor: "#000000",
    });
    if (profileId) {
      setLoading(true);
      setMessage("");
      service
        .getProfile(auth(), profileId)
        .then((data) => {
          setProfile(data.profile);
          setLoading(false);
        })
        .catch(() => {
          navigate("/profiles");
        });
    }
  }, [profileId]);

  // Edit profile handler
  const editProfileHandler = async () => {
    setLoading(true);
    setMessage("");
    service
      .editProfile(auth(), profileId, profile)
      .then((data) => {
        window.location.reload();
      })
      .catch((e) => {
        setMessage(e.response ? e.response.data.error.message : e.message);
        setLoading(false);
      });
  };

  // Edit profile form layout
  return (
    <ProfileModal
      profile={profile}
      setProfile={setProfile}
      handler={editProfileHandler}
      message={message}
      loading={loading}
      openStatus={profileId != false}
      setOpenStatus={setProfileId}
      deleteProfileHandler={deleteProfileHandler}
    />
  );
}

export default EditProfile;
