import React from "react";

export function PrimaryButton({
  onClick,
  children,
  color,
  lefticon,
  righticon,
  width = "w-fit",
  height = "h-fit",
  active,
  className,
}) {
  return (
    <button
      className={`text-center border-none bg-green-700 hover:bg-green-800 text-white font-semibold text-xs py-2 px-4 rounded-md ${color}  ${width} ${height} ${className}`}
      onClick={onClick}
    >
      <div className="flex flex-row gap-2 text-center items-center w-full">
        {lefticon && lefticon}
        {children && <span className="text-center">{children}</span>}
        {righticon && righticon}
      </div>
    </button>
  );
}

export function PrimaryButtonWithIconOnTop({ onClick, children, color, icon }) {
  return (
    <button
      className={`border-[1px] w-fit h-fit bg-green-700 hover:bg-green-800 text-white shadow-md hover:shadow-lg font-medium text-xs py-2 px-4 rounded-md ${color}`}
      onClick={onClick}
    >
      <div className="flex flex-col gap-2 items-center pt-1">
        {icon}
        <span>{children}</span>
      </div>
    </button>
  );
}

export function SecondaryButtonWithIconOnTop({
  onClick,
  children,
  color,
  icon,
  width = "w-fit",
  height = "h-fit",
  active,
}) {
  return (
    <button
      className={`border-[1px] ${
        active
          ? "bg-green-500 shadow-none hover:shadow-none text-white"
          : "bg-white shadow-md hover:shadow-lg"
      } font-medium text-xs py-2 px-4 rounded-md text-gray-700 ${color} ${width} ${height}`}
      onClick={onClick}
    >
      <div className="flex flex-col gap-2 items-center pt-1">
        {icon}
        <span>{children}</span>
      </div>
    </button>
  );
}

export function SecondaryButton({
  onClick,
  children,
  className,
  color,
  lefticon,
  righticon,
  width = "w-fit",
  height = "h-fit",
  active,
}) {
  return (
    <button
      className={` bg-white shadow-md hover:shadow-lg font-semibold text-xs py-2 px-4 rounded-md text-gray-700 ${color}  ${width} ${height} ${className}`}
      onClick={onClick}
    >
      <div className="flex flex-row gap-2 items-center">
        {lefticon && lefticon}
        {children && <span className="text-center">{children}</span>}
        {righticon && righticon}
      </div>
    </button>
  );
}

export function TertiaryButton({
  children,
  color,
  lefticon,
  righticon,
  onClick,
  width = "w-fit",
  height = "h-fit",
  className,
  active,
}) {
  return (
    <button
      className={` bg-light-green-100 hover:bg-light-green-200 font-semibold text-xs py-2 px-4 rounded-md text-green-600 ${color}  ${width} ${height} ${className}`}
      onClick={onClick}
    >
      <div className="flex flex-row gap-2 items-center justify-center">
        {lefticon && lefticon}
        {children && <span className="text-center">{children}</span>}
        {righticon && righticon}
      </div>
    </button>
  );
}

export function GhostButton({
  children,
  color,
  lefticon,
  righticon,
  onClick,
  className,
  width = "w-fit",
  height = "h-fit",
  active,
}) {
  const pxValue = children ? "px-4" : "px-2";

  return (
    <button
      className={` bg-transparent hover:bg-gray-100 font-normal text-xs py-2 ${pxValue} rounded-md text-gray-600 ${color}  ${width} ${height} ${className}`}
      onClick={onClick}
    >
      <div className="flex flex-row gap-2 items-center justify-center">
        {lefticon && lefticon}
        {children && <span className="text-center">{children}</span>}
        {righticon && righticon}
      </div>
    </button>
  );
}
