import React, { useEffect, useState } from "react";
import { Alert, Typography } from "@material-tailwind/react";

function Message({ message }) {
  // Timeout message after 5 seconds
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [message]);

  return (
    <>
      {show ? (
        <div className='absolute right-4 bottom-4 items-center w-fit '>
          {" "}
          <Alert className='flex flex-row border-light-green-400 border-2 my-2 bg-green-500 shadow-md rounded-md justify-center items-center'>
            <Typography className=' text-sm font-semibold text-white text-center w-[100%]'>
              {message}
            </Typography>
          </Alert>
        </div>
      ) : null}
    </>
  );
}

export default Message;
