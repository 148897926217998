import React from "react";
import { Select, Option } from "@material-tailwind/react";
import { Typography } from "@material-tailwind/react";

function LanguageSelector({ post, setPost }) {
  const langs = {
    en: "English",
    hk: "HK Traditional Chinese",
    cn: "Simplified Chinese",
    fr: "French",
    de: "German",
    es: "Spanish",
  };

  return (
    <div className='w-full'>
      <Typography
        variant='paragraph'
        className='text-sm font-medium font-outfit mb-1 text-gray-800'
      >
        Post Language
      </Typography>
      <Select
        className='border border-gray-300 focus:border-2 focus:border-gray-400 '
        labelProps={{ className: "before:content-none after:content-none" }}
        value={post.lang}
        onChange={(e) => setPost({ ...post, lang: e })}
      >
        {Object.keys(langs).map((lang) => (
          <Option
            key={lang}
            value={lang}
          >
            {langs[lang]}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default LanguageSelector;
