import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";

export default function NotFound404() {
  const navigate = useNavigate();
  const signOut = useSignOut();

  const logoutHandler = () => {
    signOut();
    navigate("/");
  };

  return (
    <div className="w-full  flex flex-col  justify-center gap-3 sm:items-center sm:text-center sm:mt-10">
      <Typography variant="h2" className="font-normal text-gray-700">
        404 Not Found
      </Typography>
      <div className="flex flex-row gap-4 flex-wrap" />

      <div className="flex flex-row gap-4">
        <a href="/home">
          <Button
            variant="filled"
            className="font-normal text-white  border-gray-200"
          >
            Back to Home Page
          </Button>
        </a>

        <Button
          variant="filled"
          className="font-normal text-white  bg-red-600"
          onClick={logoutHandler}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
