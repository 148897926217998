import { useState } from "react";
import Loading from "../../components/Loading";
import Message from "../../components/Message";
import ModalBlock from "../../components/ModalBlock";
import { Input } from "../../components/FormElements";
import {
  GhostButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "../../components/Buttons";
import { Table } from "../../components/Table";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { searchWeb } from "../ragUtil";

function FetchSearchModal({ postModalPage, setPostModalPage, post, setPost }) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [articles, setArticles] = useState([]);

  // Fetch search handler
  const fetchSearchHandler = async () => {
    setArticles([]);
    setLoading(true);
    setMessage("");
    try {
      const entries = await searchWeb(keywords);
      setArticles(entries);
    } catch {
      setMessage("Web search failed");
    }
    setLoading(false);
  };

  // Select article handler, go to fetch URL modal
  const selectArticleHandler = (url) => {
    setPost({ ...post, url });
    setKeywords("");
    setArticles([]);
    setPostModalPage(4);
  };

  // Fetch search form layout
  return (
    <ModalBlock
      openStatus={postModalPage === 3}
      title="Web Article Search"
      width="lg"
      primaryButton={
        <PrimaryButton
          onClick={() => {
            fetchSearchHandler();
          }}
        >
          Search
        </PrimaryButton>
      }
      secondaryButton={
        <SecondaryButton
          onClick={() => {
            setPost(null);
            setKeywords("");
            setArticles([]);
            setPostModalPage(0);
          }}
        >
          Close
        </SecondaryButton>
      }
    >
      {message ? <Message message={message} /> : null}
      {loading ? <Loading /> : null}

      <Input
        maxLength={100}
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        placeholder="e.g. 'English idioms' or 'cookie recipe'"
        required={true}
      >
        Search with Keywords
      </Input>

      {articles.length > 0 ? (
        <Table
          data={articles.map(({ title, snippet, url }, i) => ({
            title,
            snippet,
            url,
          }))}
          tableMetaData={[
            {
              column: 1,
              minWidth: "200px",
              textProperties: "text-gray-800 font-medium",
              customName: "Title",
            },
            {
              column: 2,
              minWidth: "400px",
              width: "60%",
              textProperties: "text-gray-600 font-medium",
              customName: "Content Snippet",
            },
            {
              column: 3,
              minWidth: "150px",
              textProperties: "text-gray-400 font-normal",
              customName: "Actions",
              customRender: (rowData) => (
                <div className="flex flex-row gap-2">
                  <GhostButton
                    onClick={() => window.open(rowData.url, "_blank")}
                    className="text-blue-500 underline mr-2"
                    righticon={
                      <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    }
                  ></GhostButton>
                  <TertiaryButton
                    onClick={() => selectArticleHandler(rowData.url)}
                    className="text-blue-500 underline"
                  >
                    Select
                  </TertiaryButton>
                </div>
              ),
            },
          ]}
        />
      ) : null}
    </ModalBlock>
  );
}

export default FetchSearchModal;
