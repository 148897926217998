import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import Navbar from "./components/Navbar";
import FooterBox from "./components/FooterBox";
import { GhostButton } from "./components/Buttons";
import { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

function App({ children }) {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);

  // Redirect to login page if not authenticated
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
  }, []);

  return (
    <div className='flex flex-col md:flex-row h-screen sm:gap-0 '>
      {navbarCollapsed && (
        <GhostButton
          className={`absolute z-20 top-4 left-4 border bg-white sm:hidden opacity-90`}
          lefticon={<ArrowRightIcon className='w-5 h-5' />}
          onClick={() => setNavbarCollapsed(false)}
          s
        ></GhostButton>
      )}
      <div
        className={`sm:max-w-full sm:p-0 flex flex-col gap-4 p-2 justify-between max-w-[230px] border-r-[1px] 
        ${navbarCollapsed ? "hidden" : "block"}`}
      >
        {!navbarCollapsed && (
          <>
            <Navbar />
            <FooterBox />
          </>
        )}

        {!navbarCollapsed && (
          <GhostButton
            className={`sm:hidden absolute z-20 top-6 left-[180px] border bg-white`}
            lefticon={<ArrowLeftIcon className='w-4 h-4' />}
            onClick={() => setNavbarCollapsed(true)}
          ></GhostButton>
        )}
      </div>

      <div className='sm:pt-14 md:block flex-auto w-full bg-gray-50 h-full overflow-y-scroll sm:overflow-y-auto sm:overflow-x-scroll'>
        <div className='p-8 h-full'>{children}</div>
      </div>
    </div>
  );
}

export default App;
